import React, { useState, useReducer } from "react";

import Loading from "../../components/Loading";
import { Modal, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import ClientService from "../../services/Client.services";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { IoIosSave } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

function AddClientModal(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const checkValidity = (state) => {
    if (state.fields.Name.length === 0) return false;
    if (state.fields.Tel.length < 8) return false;
    return true;
  };

  const inputReducer = (state, action) => {
    let newState = {};
    if (action.type === "NAME") {
      newState = {
        ...state,
        fields: { ...state.fields, Name: action.payload },
      };
    }

    if (action.type === "TEL") {
      newState = {
        ...state,
        fields: { ...state.fields, Tel: action.payload },
      };
    }

    if (action.type === "EMAIL") {
      newState = {
        ...state,
        fields: { ...state.fields, mail: action.payload },
      };
    }

    if (action.type === "TYPE") {
      newState = {
        ...state,
        fields: { ...state.fields, type: action.payload },
      };
    }

    setIsValid(checkValidity(newState));
    return newState;
  };

  const [clientData, dispatchClientData] = useReducer(inputReducer, {
    fields: {
      Name: "",
      Tel: "",
      mail: "",
      type: "",
      Dashboard: ["recGjaAORvBLIJBe7"],
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const body = JSON.stringify({
      records: [clientData],
    });
    const _clientService = new ClientService();
    const response = await _clientService.Add(body);
    console.log(response);
    props.setNewData((prevState) => {
      return {
        ...prevState,
        clientData: [...prevState.clientData, response.data.records[0]],
      };
    });
    setIsLoading(false);
    props.onClose();
    toast.success("Client Ajouté!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <>
        <Loading isOn={isLoading} />
        <Box sx={styles}>
          <span className="close-icon" onClick={() => props.onClose()}>
            <AiFillCloseCircle />
          </span>
          <>
            {!isLoading && (
              <form className="form">
                <Grid container rowGap={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <label className="label">Nom</label>
                      <TextField
                        fullWidth
                        label="Nom"
                        type="text"
                        value={clientData.fields.Name}
                        onChange={(e) =>
                          dispatchClientData({
                            type: "NAME",
                            payload: e.target.value,
                          })
                        }
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <label className="label">Numero Telephone</label>
                      <TextField
                        fullWidth
                        label="Num Tel"
                        type="text"
                        value={clientData.fields.Tel}
                        onChange={(e) =>
                          dispatchClientData({
                            type: "TEL",
                            payload: e.target.value,
                          })
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <label className="label">Email</label>
                      <TextField
                        fullWidth
                        label="Email"
                        type="email"
                        value={clientData.fields.mail}
                        onChange={(e) =>
                          dispatchClientData({
                            type: "EMAIL",
                            payload: e.target.value,
                          })
                        }
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <label className="label">Type</label>
                      <FormControl fullWidth>
                        <InputLabel id="type-id">Type</InputLabel>
                        <Select
                          labelId="type-id"
                          id="type"
                          value={clientData.fields.type}
                          label="Client"
                          onChange={(e) =>
                            dispatchClientData({
                              type: "TYPE",
                              payload: e.target.value,
                            })
                          }
                        >
                          <MenuItem value="B2B">B2B</MenuItem>
                          <MenuItem value="B2C">B2C</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}

            <div className={`${isLoading ? "button-loading" : "send-button"}`}>
              <Button
                size="large"
                variant="contained"
                onClick={handleSubmit}
                endIcon={<IoIosSave />}
                disabled={!isValid}
              >
                Enregistrer
              </Button>
            </div>
          </>
        </Box>
      </>
    </Modal>
  );
}

export default AddClientModal;
