import React, { useEffect, useState, useReducer } from "react";
import { useHistory, useParams } from "react-router-dom";

import Loading from "../../components/Loading";
import { TextField, Autocomplete } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MaintenanceService from "../../services/Maintenance.services";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import VehiculeService from "../../services/Vehicule.services";
import { IoIosSave } from "react-icons/io";

function UpdateMaintenance() {
  const { id: maintenanceId } = useParams();
  const [vehiculeData, setVehiculeData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const history = useHistory();

  const isAdmin = JSON.parse(localStorage.getItem("user")).Role === "admin";

  const checkValidity = (state) => {
    if (!dayjs(state.fields.Date, "YYYY-M-D").isValid()) return false;
    if (state.fields.Type.length === 0) return false;
    if (state.fields.Vehicule[0].length === 0) return false;
    if (state.fields.Price <= 0) return false;
    return true;
  };

  const inputReducer = (state, action) => {
    var newState = {};
    if (action.type === "FETCHED") {
      return action.payload;
    }
    if (action.type === "DATE") {
      const date = dayjs(action.payload, "DD/MM/YYYY").format("YYYY-M-D");
      newState = {
        ...state,
        fields: { ...state.fields, Date: date },
      };
    }

    if (action.type === "TYPE") {
      newState = {
        ...state,
        fields: { ...state.fields, Type: action.payload },
      };
    }

    if (action.type === "VEHICULE") {
      newState = {
        ...state,
        fields: { ...state.fields, Vehicule: [action.payload] },
      };
    }

    if (action.type === "NOTE") {
      newState = {
        ...state,
        fields: { ...state.fields, Note: action.payload },
      };
    }

    if (action.type === "PRICE") {
      newState = {
        ...state,
        fields: { ...state.fields, Price: parseInt(action.payload) },
      };
    }

    setIsValid(checkValidity(newState));
    return newState;
  };

  const [maintenanceData, dispatchMaintenanceData] = useReducer(
    inputReducer,
    null
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const _maintenanceService = new MaintenanceService();
      const MaintenanceRes = await _maintenanceService.GetById(maintenanceId);
      console.log(MaintenanceRes.data);
      dispatchMaintenanceData({
        type: "FETCHED",
        payload: MaintenanceRes.data,
      });

      const _vehiculeService = new VehiculeService();
      const VehiculeRes = await _vehiculeService.GetAll();
      setVehiculeData(VehiculeRes.data);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const body = JSON.stringify({
      records: [
        {
          id: maintenanceData.id,
          fields: {
            ...maintenanceData.fields,
            ID: undefined,
            NumMatricule: undefined,
          },
        },
      ],
    });
    const _maintenanceService = new MaintenanceService();
    await _maintenanceService.Edit(body);
    setIsLoading(false);
    history.push(`/maintenances`);
    toast.success("Maintenance Modifié!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <>
      <Loading isOn={isLoading} />
      {vehiculeData && maintenanceData && !isLoading && (
        <form className="form">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <label className="label">Vehicule</label>
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => {
                  const vehicule = vehiculeData.find(
                    (vehicule) => vehicule.id === option
                  ).fields;
                  return `${vehicule.NumMatricule} - ${vehicule.Type} - ${vehicule.Marque} - ${vehicule.Modele}`;
                }}
                value={maintenanceData?.fields.Vehicule[0] || null}
                options={vehiculeData.map((vehicule) => vehicule.id)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Vehicule" />
                )}
                onChange={(e, value) =>
                  value &&
                  dispatchMaintenanceData({
                    type: "VEHICULE",
                    payload: value,
                  })
                }
              />
            </Grid>

            <Grid item xs={4}>
              <label className="label">Type</label>
              <FormControl fullWidth>
                <InputLabel id="type-id">Type</InputLabel>
                <Select
                  labelId="type-id"
                  id="type"
                  value={maintenanceData?.fields.Type}
                  label="Maintenance"
                  onChange={(e) =>
                    dispatchMaintenanceData({
                      type: "TYPE",
                      payload: e.target.value,
                    })
                  }
                >
                  <MenuItem value="Reparation">Reparation</MenuItem>
                  <MenuItem value="Vidange">Vidange</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <label className="label">Date</label>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dayjs(maintenanceData.fields.Date)}
                    format="DD/MM/YYYY"
                    onChange={(value) =>
                      value.isValid() &&
                      dispatchMaintenanceData({
                        type: "DATE",
                        payload: value,
                      })
                    }
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <label className="label">Prix</label>
              <TextField
                fullWidth
                id="prix"
                label="Prix"
                type="number"
                disabled={!isAdmin}
                value={maintenanceData.fields.Price}
                onChange={(event) =>
                  dispatchMaintenanceData({
                    type: "PRICE",
                    payload: event.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={8}>
              <label className="label">Note</label>
              <TextField
                fullWidth
                id="note"
                label="Note"
                multiline
                rows={4}
                value={maintenanceData.fields.Note}
                onChange={(event) =>
                  dispatchMaintenanceData({
                    type: "NOTE",
                    payload: event.target.value,
                  })
                }
              />
            </Grid>
          </Grid>
        </form>
      )}

      <div className={`${isLoading ? "button-loading" : "send-button"}`}>
        <Button
          size="large"
          variant="contained"
          onClick={handleSubmit}
          endIcon={<IoIosSave />}
          disabled={!isValid}
        >
          Enregistrer
        </Button>
      </div>
    </>
  );
}

export default UpdateMaintenance;
