import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Filter from "../../components/Filter";
import Loading from "../../components/Loading";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { AiFillEdit, AiFillPlusCircle } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import Button from "@mui/material/Button";
import CircuitService from "../../services/Circuit.services";
import { ToastContainer, toast } from "react-toastify";

function CircuitList() {
  const [circuits, setCircuits] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [filteredData, setFilteredData] = useState(null);

  const _circuitService = new CircuitService();
  const isAdmin = JSON.parse(localStorage.getItem("user")).Role === "admin";
  const settings = JSON.parse(localStorage.getItem("Settings")).fields;

  useEffect(() => {
    setIsLoading(true);
    _circuitService.GetAll().then((res) => {
      setCircuits(res.data);
      console.log(res.data);
      setIsLoading(false);
    });
  }, [reload]);

  const handleDeleteCircuit = async (id) => {
    const foundCircuit = circuits.find((circuit) => circuit.id === id);
    if (foundCircuit.fields.task) {
      toast.error("circuit a deja des reservations", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      await _circuitService.Delete(id);
      setReload((prevState) => !prevState);
      toast.success("Circuit Supprimé!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const columns = [
    {
      field: "trajet",
      headerName: "Trajet",
      type: "string",
      width: 250,
      valueGetter: (params) => {
        return params.row.fields.trajet;
      },
    },
    {
      field: "price",
      headerName: "Prix de base",
      width: 200,
      valueGetter: (params) => {
        return `${parseInt(params.row.fields.Price)} ${settings.currency}`;
      },
    },
    {
      field: "totalTask",
      headerName: "Total Reservations",
      width: 250,
      valueGetter: (params) => params.row.fields.totaltask,
    },
    {
      field: "totalPrice",
      headerName: "Total Prix",
      width: 250,
      valueGetter: (params) =>
        `${params.row.fields.totalprice} ${settings.currency}`,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => {
        return (
          <div style={{ fontSize: "1.7rem" }}>
            <Link to={`/circuits/${params.row.id}/edit`}>
              <span style={{ color: "rgb(53,88,199)", paddingRight: "50%" }}>
                <AiFillEdit />
              </span>
            </Link>

            {isAdmin && (
              <span
                style={{ color: "rgba(255,77,77,1)", cursor: "pointer" }}
                onClick={() => {
                  handleDeleteCircuit(params.row.id);
                }}
              >
                <MdDelete />
              </span>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Loading isOn={isLoading} />
      <ToastContainer />
      <div
        className={`${
          !circuits || isLoading ? "button-loading" : "send-button list"
        }`}
      >
        <Link to="/circuits/add">
          <Button size="large" variant="contained">
            Ajouter
            <span className="send-button-icon">
              <AiFillPlusCircle />
            </span>
          </Button>
        </Link>
      </div>
      {circuits && !isLoading && (
        <>
          <Filter Circuit data={circuits} setFilteredData={setFilteredData} />
          <Box
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            <DataGrid
              rows={filteredData || circuits}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
            />
          </Box>
        </>
      )}
    </>
  );
}

export default CircuitList;
