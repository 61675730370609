import React from "react";

import { TbArrowBarRight, TbArrowBarToLeft } from "react-icons/tb";
import { AiFillCaretRight, AiFillCaretLeft } from "react-icons/ai";
import { GiBus } from "react-icons/gi";
import dayjs from "dayjs";
import "./Task.css";

const getBackgroundColor = (data) => {
  if (data === "payer") return { backgroundColor: "#70e000" };
  else if (data === "partial") return { backgroundColor: "#f26419" };
  else return { backgroundColor: "#d90429" };
};

function Task(props) {
  const { start, end, payment } = props;

  return (
    <div className="task-content-container">
      <div className="task-content">
        <div className="task-data">
          <span className="task-icon">
            <span className="bus-icon">
              <GiBus />
            </span>
            <span className="arrow-icon">
              <AiFillCaretRight />
            </span>
          </span>
          {dayjs(start).format("DD/MM/YYYY LT")}
        </div>
        <div className="task-data">
          <span className="task-icon">
            <span className="arrow-icon">
              <AiFillCaretLeft />
            </span>
            <span className="bus-icon rotate">
              <GiBus />
            </span>
          </span>
          {dayjs(end).format("DD/MM/YYYY LT")}
        </div>
      </div>

      <div className="task-payment" style={getBackgroundColor(payment)}>
        {payment}
      </div>
    </div>
  );
}

export default Task;
