import React from "react";

import "./QuittanceTable.scss";

function QuittanceTable({ quittance }) {
  return (
    <div className="quittance-table">
      <div className="table-row header">
        <div className="table-item left">N°Quittance</div>
        <div className="table-item">Montant Payé</div>
      </div>
      {quittance.map((quitt, idx) => (
        <div key={idx} className="table-row">
          <div className="table-item left">{quitt.numero}</div>
          <div className="table-item">{quitt.montant}</div>
        </div>
      ))}
    </div>
  );
}

export default QuittanceTable;
