import request from "../configs";

export default class DashboardService {
  constructor() {
    this.request = request;
    this.userData = this.getUserData();
    this.headers = {
      Authorization: `Bearer ${this.userData.AIRTABLEAPI}`,
      "content-type": "application/json",
    };
  }

  getUserData() {
    return JSON.parse(localStorage.getItem("user"));
  }

  async GetAll() {
    const headers = this.headers;
    const res = await this.request.get(
      `https://api.airtable.com/v0/${this.userData.DATBASENAME}/Dashboard`,
      {
        headers,
        params: {
          fields: [
            "NumberTasks",
            "NumberClients",
            "NumberVehicules",
            "NumberChauffeurs",
            "NumberAlerts",
            "NumberCircuits",
          ],
        },
      }
    );
    return { data: res.data.records };
  }
}
