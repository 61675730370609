import React, { useEffect, useState, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import QuittanceCard from "./QuittanceCard";
import Loading from "../../components/Loading";
import { TextField, Autocomplete } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import TaskService from "../../services/Task.services";
import Button from "@mui/material/Button";
import { getAllData } from "../../services";
import { toast } from "react-toastify";
import { IoIosSave } from "react-icons/io";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import "./UpdateTask.css";
import QuittanceTable from "./QuittanceTable";

function UpdateTask() {
  const { id: taskId } = useParams();
  const [allData, setAllData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [circuitPrice, setCircuitPrice] = useState("");
  const [availableVehicule, setAvailableVehicule] = useState([]);
  const [availableChauffeur, setAvailableChauffeur] = useState([]);
  const [quittance, setQuittance] = useState([]);
  const [quittanceCard, setQuittanceCard] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const history = useHistory();

  const _taskService = new TaskService();
  const isAdmin = JSON.parse(localStorage.getItem("user")).Role === "admin";

  const getCircuitPrice = (value) => {
    const circuit = allData.circuitData.find((circuit) => circuit.id === value);
    return circuit.fields.Price;
  };

  const checkValidity = (state) => {
    if (!dayjs(state.fields.TaskStart).isValid()) return false;
    if (!dayjs(state.fields.TaskEnd).isValid()) return false;
    if (dayjs(state.fields.TaskEnd).isBefore(state.fields.TaskStart))
      return false;
    if (state.fields.vehicule[0].length === 0) return false;
    if (state.fields.Client[0].length === 0) return false;
    if (state.fields.circuit[0].length === 0) return false;
    if (state.fields.chauffeur[0].length === 0) return false;
    if (state.fields.Payment.length === 0) return false;
    if (state.fields.Prix < 0) return false;
    if (state.fields.NPlaces < 0) return false;
    return true;
  };

  const getAvailableData = (taskStart, taskEnd, data) => {
    const overlapping = data.taskData.filter(
      (task) =>
        (dayjs(taskStart).isSameOrBefore(task.fields.TaskEnd) &&
          dayjs(taskEnd).isSameOrAfter(task.fields.TaskEnd)) ||
        (dayjs(taskEnd).isSameOrAfter(task.fields.TaskStart) &&
          dayjs(taskStart).isSameOrBefore(task.fields.TaskStart)) ||
        (dayjs(taskStart).isAfter(task.fields.TaskStart) &&
          dayjs(taskEnd).isBefore(task.fields.TaskEnd))
    );

    let reservedVehicules = [];
    let reservedChauffeur = [];

    overlapping.forEach((task) => {
      reservedVehicules = [
        ...reservedVehicules,
        ...(task.fields.vehicule || []),
      ];
    });

    overlapping.forEach((task) => {
      reservedChauffeur = [
        ...reservedChauffeur,
        ...(task.fields.chauffeur || []),
      ];
    });

    const availableVehicule = data.vehiculeData
      .filter((vehicule) => !reservedVehicules.includes(vehicule.id))
      .map((vehicule) => vehicule.id);

    const availableChauffeur = data.chauffeurData
      .filter((chauffeur) => !reservedChauffeur.includes(chauffeur.id))
      .map((chauffeur) => chauffeur.id);

    setAvailableVehicule(availableVehicule);
    setAvailableChauffeur(availableChauffeur);
  };

  const inputReducer = (state, action) => {
    let newState = {};
    if (action.type === "FETCHED") {
      return action.payload;
    }

    if (action.type === "TASK_START_DAY") {
      const time = dayjs(state.fields.TaskStart).format("LT");
      const day = dayjs(action.payload, "DD/MM/YYYY").format("L");
      const newDate = dayjs(`${day} ${time}`).toISOString();

      getAvailableData(newDate, state.fields.TaskEnd, allData);

      newState = {
        ...state,
        fields: { ...state.fields, TaskStart: newDate },
      };
    }

    if (action.type === "TASK_START_TIME") {
      const time = dayjs(action.payload, "HH:mm").format("LT");
      const day = dayjs(state.fields.TaskStart).format("L");
      const newDate = dayjs(`${day} ${time}`).toISOString();

      getAvailableData(newDate, state.fields.TaskEnd, allData);

      newState = {
        ...state,
        fields: { ...state.fields, TaskStart: newDate },
      };
    }

    if (action.type === "TASK_END_DAY") {
      const time = dayjs(state.fields.TaskEnd).format("LT");
      const day = dayjs(action.payload, "DD/MM/YYYY").format("L");
      const newDate = dayjs(`${day} ${time}`).toISOString();

      getAvailableData(state.fields.TaskStart, newDate, allData);

      newState = {
        ...state,
        fields: { ...state.fields, TaskEnd: newDate },
      };
    }

    if (action.type === "TASK_END_TIME") {
      const time = dayjs(action.payload, "HH:mm").format("LT");
      const day = dayjs(state.fields.TaskEnd).format("L");
      const newDate = dayjs(`${day} ${time}`).toISOString();

      getAvailableData(state.fields.TaskStart, newDate, allData);

      newState = {
        ...state,
        fields: { ...state.fields, TaskEnd: newDate },
      };
    }

    if (action.type === "PAYMENT") {
      newState = {
        ...state,
        fields: { ...state.fields, Payment: action.payload },
      };
    }

    if (action.type === "PRICE") {
      newState = {
        ...state,
        fields: { ...state.fields, Prix: parseInt(action.payload) },
      };
    }

    if (action.type === "CIRCUIT") {
      setCircuitPrice(getCircuitPrice(action.payload));
      newState = {
        ...state,
        fields: {
          ...state.fields,
          circuit: [action.payload],
        },
      };
    }

    if (action.type === "CHAUFFEUR") {
      newState = {
        ...state,
        fields: { ...state.fields, chauffeur: [action.payload] },
      };
    }

    if (action.type === "VEHICULE") {
      newState = {
        ...state,
        fields: { ...state.fields, vehicule: [action.payload] },
      };
    }

    if (action.type === "CLIENT") {
      newState = {
        ...state,
        fields: { ...state.fields, Client: [action.payload] },
      };
    }

    if (action.type === "PLACES") {
      newState = {
        ...state,
        fields: { ...state.fields, NPlaces: parseInt(action.payload) },
      };
    }

    if (action.type === "POINT_RAMASSAGE") {
      newState = {
        ...state,
        fields: { ...state.fields, PointRamassage: action.payload },
      };
    }

    setIsValid(checkValidity(newState));
    return newState;
  };

  const [taskData, dispatchTaskData] = useReducer(inputReducer, null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const allDataRes = await getAllData(true);
      setAllData(allDataRes);
      console.log(allDataRes);

      const taskDataRes = await _taskService.GetById(taskId);
      dispatchTaskData({ type: "FETCHED", payload: taskDataRes.data });
      setQuittance(JSON.parse(taskDataRes.data.fields.Quittance || "[]"));
      setCircuitPrice(taskDataRes.data.fields.PriceCircuit);
      getAvailableData(
        taskDataRes.data.fields.TaskStart,
        taskDataRes.data.fields.TaskEnd,
        allDataRes //passed the allDataRes because when the code reaches this line the state is not mutated yet
      );
      console.log(taskDataRes.data);

      setIsLoading(false);
    };

    fetchData();
  }, [taskId]);

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const body = JSON.stringify({
      records: [
        {
          id: taskData.id,
          fields: {
            ...taskData.fields,
            Quittance: JSON.stringify(quittance),
            Prix: taskData.fields.Prix || circuitPrice,
            ID: undefined,
            NumMatricule: undefined,
            RouteName: undefined,
            PriceCircuit: undefined,
            NameChauffeur: undefined,
            NameClient: undefined,
            TelClient: undefined,
          },
        },
      ],
    });
    const _taskService = new TaskService();
    await _taskService.Edit(body);
    setIsLoading(false);
    history.push(`/tasks`);
    toast.success("Reservation Modifié.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <>
      <Loading isOn={isLoading} />
      <QuittanceCard
        open={quittanceCard}
        onClose={() => setQuittanceCard(false)}
        quittance={setQuittance}
      />
      {taskData && allData && !isLoading && (
        <form className="form">
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <label className="label">Jour Departure</label>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dayjs(taskData.fields.TaskStart)}
                    format="DD/MM/YYYY"
                    onChange={(value) =>
                      value.isValid() &&
                      dispatchTaskData({
                        type: "TASK_START_DAY",
                        payload: value,
                      })
                    }
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <label className="label">Temp Departure</label>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Basic time picker"
                    value={dayjs(taskData.fields.TaskStart)}
                    format="HH:mm"
                    onChange={(value) =>
                      value.isValid() &&
                      dispatchTaskData({
                        type: "TASK_START_TIME",
                        payload: value,
                      })
                    }
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <label className="label">Jour Arrivage</label>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dayjs(taskData.fields.TaskEnd)}
                    format="DD/MM/YYYY"
                    onChange={(value) =>
                      value.isValid() &&
                      dispatchTaskData({
                        type: "TASK_END_DAY",
                        payload: value,
                      })
                    }
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <label className="label">Temp Arrivage</label>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Basic time picker"
                    value={dayjs(taskData.fields.TaskEnd)}
                    format="HH:mm"
                    onChange={(value) =>
                      value.isValid() &&
                      dispatchTaskData({
                        type: "TASK_END_TIME",
                        payload: value,
                      })
                    }
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <label className="label">Client</label>
              <Autocomplete
                disablePortal
                getOptionLabel={(option) =>
                  allData.clientData.find((client) => client.id === option)
                    .fields.Name
                }
                value={taskData?.fields.Client[0] || null}
                options={allData.clientData.map((client) => client.id)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Client" />
                )}
                onChange={(e, value) =>
                  value &&
                  dispatchTaskData({
                    type: "CLIENT",
                    payload: value,
                  })
                }
              />
            </Grid>

            <Grid item xs={3}>
              <label className="label">Vehicule</label>
              <Autocomplete
                disablePortal
                getOptionDisabled={(option) =>
                  !(
                    availableVehicule.includes(option) ||
                    taskData.fields.vehicule.includes(option)
                  )
                }
                getOptionLabel={(option) => {
                  const vehicule = allData.vehiculeData.find(
                    (vehicule) => vehicule.id === option
                  ).fields;
                  return `${vehicule.NumMatricule} - ${vehicule.Type} - ${vehicule.Marque} - ${vehicule.Modele}`;
                }}
                value={taskData?.fields.vehicule[0] || null}
                options={allData.vehiculeData.map((vehicule) => vehicule.id)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Vehicule" />
                )}
                onChange={(e, value) =>
                  value &&
                  dispatchTaskData({
                    type: "VEHICULE",
                    payload: value,
                  })
                }
              />
            </Grid>

            <Grid item xs={3}>
              <label className="label">Circuit</label>
              <Autocomplete
                disablePortal
                getOptionLabel={(option) =>
                  allData.circuitData.find((circuit) => circuit.id === option)
                    .fields.trajet
                }
                value={taskData?.fields.circuit[0] || null}
                options={allData.circuitData.map((circuit) => circuit.id)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Circuit" />
                )}
                onChange={(e, value) =>
                  value &&
                  dispatchTaskData({
                    type: "CIRCUIT",
                    payload: value,
                  })
                }
              />
            </Grid>

            <Grid item xs={3}>
              <label className="label">Point de Ramassage</label>
              <TextField
                fullWidth
                id="outlined-number"
                label="Point de ramassage"
                value={taskData?.fields.PointRamassage}
                onChange={(e) =>
                  dispatchTaskData({
                    type: "POINT_RAMASSAGE",
                    payload: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={3}>
              <label className="label">Nombre de places</label>
              <TextField
                fullWidth
                id="outlined-number"
                label="Nb places"
                type="number"
                value={taskData?.fields.NPlaces}
                onChange={(e) =>
                  dispatchTaskData({
                    type: "PLACES",
                    payload: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={3}>
              <label className="label">Prix de base</label>
              <TextField
                fullWidth
                id="outlined-number"
                label="Prix de base"
                type="number"
                value={circuitPrice}
                disabled
              />
            </Grid>

            <Grid item xs={3}>
              <label className="label">Prix</label>
              <TextField
                fullWidth
                id="outlined-number"
                label="Prix"
                type="number"
                disabled={!isAdmin}
                value={taskData.fields.Prix || circuitPrice}
                onChange={(e) =>
                  dispatchTaskData({
                    type: "PRICE",
                    payload: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={3}>
              <label className="label">Payement</label>
              <FormControl fullWidth>
                <InputLabel id="payment-id">Payement</InputLabel>
                <Select
                  labelId="payment-id"
                  id="payement"
                  value={taskData.fields.Payment}
                  label="Payement"
                  onChange={(e) =>
                    dispatchTaskData({
                      type: "PAYMENT",
                      payload: e.target.value,
                    })
                  }
                >
                  <MenuItem value="payer">Payer</MenuItem>
                  <MenuItem value="partial">Partielle</MenuItem>
                  <MenuItem value="non payer">Non Payer</MenuItem>
                  <MenuItem value="Annuler">Annuler</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <label className="label">Chauffeur</label>
              <Autocomplete
                disablePortal
                getOptionDisabled={(option) =>
                  !(
                    availableChauffeur.includes(option) ||
                    taskData.fields.chauffeur.includes(option)
                  )
                }
                getOptionLabel={(option) =>
                  allData.chauffeurData.find(
                    (chauffeur) => chauffeur.id === option
                  ).fields.Name
                }
                value={taskData?.fields.chauffeur[0] || null}
                options={allData.chauffeurData.map((chauffeur) => chauffeur.id)}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Chauffeur" />
                )}
                onChange={(e, value) =>
                  value &&
                  dispatchTaskData({
                    type: "CHAUFFEUR",
                    payload: value,
                  })
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <label className="label">Quittance</label>
              <div className="quittance-container">
                <QuittanceTable quittance={quittance} />
                <div className="add-icon">
                  <Fab color="primary">
                    <AddIcon onClick={() => setQuittanceCard(true)} />
                  </Fab>
                </div>
              </div>
            </Grid>
          </Grid>
        </form>
      )}

      <div className={`${isLoading ? "button-loading" : "send-button"}`}>
        <Button
          size="large"
          variant="contained"
          onClick={handleSubmit}
          endIcon={<IoIosSave />}
          disabled={!isValid}
        >
          Enregistrer
        </Button>
      </div>
    </>
  );
}

export default UpdateTask;
