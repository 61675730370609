import React, { useRef } from "react";

import { useReactToPrint } from "react-to-print";
import { Modal, Box } from "@mui/material";
import logo from "../Auth/walid-voyage.png";
import dayjs from "dayjs";
import { FaFileDownload } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";

import "./MissionOrder.scss";

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%) scale(.8) ",
  width: "1500px",
  bgcolor: "background.paper",
  p: 4,
};

const printBoxStyles = {
  bgcolor: "background.paper",
  width: "60px",
  height: "60px",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

function MissionOrder(props) {
  const { taskData, open, close } = props;
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Mission d'order",
  });

  const settings = JSON.parse(localStorage.getItem("Settings")).fields;

  const tableData = [
    {
      title: "Date:",
      value: `${dayjs(taskData?.TaskStart).format(
        "DD/MM/YYYY HH:mm"
      )} - ${dayjs(taskData?.TaskEnd).format("DD/MM/YYYY HH:mm")}`,
    },
    {
      title: "Véhicule:",
      value: taskData?.NumMatricule,
    },
    {
      title: "Guide:",
      value: "",
    },
    {
      title: "Itinéraire:",
      value: taskData?.RouteName,
    },
    {
      title: "Chauffeur:",
      value: taskData?.NameChauffeur,
    },
    {
      title: "Nombre De Places:",
      value: taskData?.NPlaces,
    },
    {
      title: "Observation:",
      value: "",
    },
    {
      title: "Km arrivé:",
      value: "",
    },
    {
      title: "Km départ:",
      value: "",
    },
    {
      title: "Km parcourt:",
      value: "",
    },
  ];

  let numQuittance = "";
  let montant = 0;
  if (taskData?.Quittance) {
    const quittance = JSON.parse(taskData.Quittance || "[]");
    numQuittance = quittance.reduce(
      (acc, value) => acc + value.numero + "/",
      ""
    );
    montant = quittance.reduce(
      (acc, value) => acc + parseInt(value.montant),
      0
    );
  }

  return (
    <Modal open={open} onClose={close}>
      <>
        <Box sx={styles}>
          <div ref={componentRef} id="print">
            <div className="page-container">
              <div className="mission-order">
                <div className="page-header">
                  <div className="header-logo">
                    <img
                      src={settings.logo[0].url}
                      alt="walid-voyage-logo"
                      className="walid-voyage-logo"
                    />
                    <div className="logo-text">{settings.Name}</div>
                  </div>
                  <div className="header-text">Votre voyage..Notre passion</div>
                </div>
                <div className="page-main">
                  <div className="page-main-header">
                    Order De Mission - {taskData?.ID}
                  </div>
                  <div className="page-main-table">
                    <div className="table-row header">
                      <div className="row-item">{taskData?.RouteName}</div>
                    </div>
                    {tableData.map((cell) => (
                      <div className="table-row" key={cell.title}>
                        <div className="table-item title">{cell.title}</div>
                        <div className="table-item value">{cell.value}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="page-footer">
                  <div>Sousse le .....</div>
                  <div>Service Transport:(Signature)</div>
                </div>
              </div>

              <div className="mission-order-admin">
                <div className="page-header">
                  <div className="header-logo">
                    <img
                      src={settings.logo[0].url}
                      alt="walid-voyage-logo"
                      className="walid-voyage-logo"
                    />
                    <div className="logo-text">{settings.Name}</div>
                  </div>
                  <div className="header-text">Votre voyage..Notre passion</div>
                </div>
                <div className="page-main">
                  <div className="page-main-header">
                    Order De Mission Administratif - {taskData?.ID}
                  </div>
                  <div className="page-main-table">
                    <div className="table-row header">
                      <div className="row-item">{taskData?.RouteName}</div>
                    </div>
                    {tableData.map((cell) => (
                      <div className="table-row" key={cell.title}>
                        <div className="table-item title">{cell.title}</div>
                        <div className="table-item value">{cell.value}</div>
                      </div>
                    ))}
                  </div>
                  <div className="admin-table">
                    <div className="admin-table-row">
                      <div className="admin-table-title">Prix de vente</div>
                      <div className="admin-table-value">
                        {taskData?.Prix || taskData?.PriceCircuit}{" "}
                        {settings.currency}
                      </div>
                    </div>
                    <div className="admin-table-row">
                      <div className="admin-table-title">Status</div>
                      <div className="admin-table-value">
                        {taskData?.Payment}
                      </div>
                    </div>
                    <div className="admin-table-row">
                      <div className="admin-table-title">N°Quittance</div>
                      <div className="admin-table-value">
                        {montant} {settings.currency} | QT:{numQuittance}
                      </div>
                    </div>
                    <div className="admin-table-row">
                      <div className="admin-table-title">Fond de roulement</div>
                      <div className="admin-table-value"></div>
                    </div>
                  </div>
                </div>
                <div className="page-footer">
                  <div>Sousse le {dayjs().format("DD/MM/YYYY HH:mm")}</div>
                  <div>Service Transport:(Signature)</div>
                </div>
              </div>
            </div>
          </div>
        </Box>
        <div className="actions-buttons">
          <Box sx={printBoxStyles} onClick={handlePrint}>
            <div className="action-button print-button">
              <FaFileDownload />
            </div>
          </Box>
          <Box sx={printBoxStyles} onClick={close}>
            <div className="action-button">
              <FcCancel />
            </div>
          </Box>
        </div>
      </>
    </Modal>
  );
}

export default MissionOrder;
