import React, { Component } from "react";
import { AwsomeBtn, BtnOpacity, Btn } from "../../components/Buttons";
import { Responsive } from "../../components/Responsive";
import request, { baseURL } from "../../configs";
import { ToastContainer, toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import "react-toastify/dist/ReactToastify.css";
import bgImage from "./background.jpeg";
import walidLogo from "./walid-voyage.png";
import SettingService from "../../services/Setting.services";

import "./styles.scss";

class Login extends Component {
  constructor(props) {
    super();
    this.request = request;
  }
  state = {
    username: "",
    password: "",
    loading: false,
  };

  gettoken() {
    this.setState({ loading: true });
    this.request
      .post(`${baseURL}`, {
        username: this.state.username,
        password: this.state.password,
      })
      .then((res) => {
        if (
          typeof res.data === "string" &&
          res.data.trim() ===
            "No record found with the provided username and password."
        ) {
          this.setState({ loading: false });
          throw new Error();
        }
        toast.error(res.data.code, {
          position: toast.POSITION.TOP_RIGHT,
        });
        localStorage.setItem("user", JSON.stringify(res.data));
        const _settingService = new SettingService();
        _settingService.GetAll().then((res) => {
          localStorage.setItem("Settings", JSON.stringify(res.data[0]));
          setTimeout(() => {
            this.props.history.push("/");
          }, 2000);
        });
      })
      .catch((res) => {
        this.setState({ loading: false });
        toast.error("Invalid Credentials", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  render() {
    return (
      <div className="login-container">
        <ToastContainer />
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", width: "60%", position: "relative" }}>
            <div className="mask" />
            <img
              src={bgImage}
              alt="background"
              className="login-background-image"
            />
          </div>

          <div className="login-card">
           <img
              src={walidLogo}
              alt="Walid Voyage"
              className="walid-voyage-logo"
            />
            <div className="tac login-text">
              <h1 className="f-300">Connexion </h1>
            </div>

            <div className="login-input-container">
              <TextField
                id="standard-basic"
                label="Username"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  this.setState({ username: e.target.value });
                }}
              />

              <TextField
                id="standard-basic"
                label="Password"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  this.setState({ password: e.target.value });
                }}
              />

              <LoadingButton
                onClick={() => this.gettoken()}
                loading={this.state.loading}
                variant="contained"
                size="large"
                fullWidth
              >
                Connexion
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
