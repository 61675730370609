import React from "react";

import dayjs from "dayjs";
import "./Alert.css";

const getDaysLeft = (time) => {
  const daysLeft = dayjs(time).diff(dayjs(), "day");
  const data = { daysLeft };
  if (daysLeft < 0) data.bgColor = "#bcb8b1";
  else if (daysLeft <= 7) data.bgColor = "#d90429";
  else if (daysLeft <= 14) data.bgColor = "#f26419";
  else data.bgColor = "#70e000";
  return data;
};

const Alert = (props) => {
  const { matricule, date, type } = props;

  const alertData = getDaysLeft(date);

  return (
    <div className="alert-content-container">
      <div className="alert-content">
        <span>{matricule}</span>
        <span>{dayjs(date).format("DD/MM/YYYY")}</span>
        <span>{type}</span>
      </div>

      <div
        className="alert-important"
        style={{ backgroundColor: alertData.bgColor }}
      >
        {alertData.daysLeft} jours restant
      </div>
    </div>
  );
};

export default Alert;
