import React from "react";

import { Modal, Box } from "@mui/material";
import { AiFillCloseCircle } from "react-icons/ai";

const styles = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

export default function NoteCard(props) {
  return (
    <Modal open={props.openModal} onClose={props.closeModal}>
      <Box sx={styles} position="relative">
        <h3>Note: </h3>
        <p style={{ whiteSpace: "pre" }}>{props.note}</p>
        <span className="close-icon" onClick={props.closeModal}>
          <AiFillCloseCircle />
        </span>
      </Box>
    </Modal>
  );
}
