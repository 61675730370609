import request from "../configs";

export default class ChauffeurService {
  constructor() {
    this.request = request;
    this.userData = this.getUserData();
    this.headers = {
      Authorization: `Bearer ${this.userData.AIRTABLEAPI}`,
      "content-type": "application/json",
    };
  }

  getUserData() {
    return JSON.parse(localStorage.getItem("user"));
  }

  async GetAll(params = {}) {
    const headers = this.headers;
    let res = await this.request.get(
      `https://api.airtable.com/v0/${this.userData.DATBASENAME}/chauffeur`,
      { headers, params }
    );

    let data = [...res.data.records];
    while (res.data.offset) {
      res = await this.GetMore(res.data.offset);
      data = [...data, ...res.data.records];
    }
    return { data };
  }

  GetMore(offset) {
    const headers = this.headers;
    return this.request.get(
      `https://api.airtable.com/v0/${this.userData.DATBASENAME}/chauffeur?&offset=${offset}`,
      { headers }
    );
  }

  GetById(id) {
    const headers = this.headers;
    return this.request.get(
      `https://api.airtable.com/v0/${this.userData.DATBASENAME}/chauffeur/${id}`,
      { headers }
    );
  }

  Edit(body) {
    const headers = this.headers;
    return this.request.patch(
      `https://api.airtable.com/v0/${this.userData.DATBASENAME}/chauffeur`,
      body,
      { headers }
    );
  }

  uploadFileToServeur = (file) => {
    const url = "http://upload.awslabs.tn/Upload.php";

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    // Extract the base64-encoded image data from the data URL
    const base64Image = file.split(",")[1];
    const formData = new FormData();
    formData.append("image", base64Image);
    formData.append("image_type", "jpg");

    return this.request.post(url, formData, { headers });
  };

  Add(body) {
    const headers = this.headers;
    return this.request.post(
      `https://api.airtable.com/v0/${this.userData.DATBASENAME}/chauffeur`,
      body,
      { headers }
    );
  }

  Delete(id) {
    const headers = this.headers;
    return this.request.delete(
      `https://api.airtable.com/v0/${this.userData.DATBASENAME}/chauffeur?&records[]=${id}`,
      {
        headers: {
          ...headers,
          "content-type": "application/x-www-form-urlencoded",
        },
      }
    );
  }
}
