import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Filter from "../../components/Filter";
import Loading from "../../components/Loading";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { AiFillEdit, AiFillPlusCircle } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import Button from "@mui/material/Button";
import AlertService from "../../services/Alert.services";
import { ToastContainer, toast } from "react-toastify";

const getDaysLeft = (time) => {
  const daysLeft = dayjs(time).diff(dayjs(), "day");
  const data = { daysLeft };
  if (daysLeft < 0) data.status = "passed";
  else if (daysLeft <= 7) data.status = "high";
  else if (daysLeft <= 14) data.status = "medium";
  else data.status = "low";
  return data;
};

function AlertList() {
  const [alerts, setAlerts] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [filteredData, setFilteredData] = useState(null);

  const _alertService = new AlertService();
  const isAdmin = JSON.parse(localStorage.getItem("user")).Role === "admin";

  useEffect(() => {
    setIsLoading(true);
    _alertService.GetAll().then((res) => {
      setAlerts(res.data);
      console.log(res.data);
      setIsLoading(false);
    });
  }, [reload]);

  const handleDeleteAlert = async (id) => {
    await _alertService.Delete(id);
    setReload((prevState) => !prevState);
    toast.success("Alert Supprimé!", { position: toast.POSITION.TOP_RIGHT });
  };

  const columns = [
    {
      field: "matricule",
      headerName: "Matricule",
      width: 300,
      valueGetter: (params) => {
        return params.row.fields.NumMatricule;
      },
    },
    {
      field: "type",
      headerName: "Type",
      width: 300,
      valueGetter: (params) => {
        return params.row.fields.Type;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 200,
      valueGetter: (params) =>
        dayjs(params.row.fields.Date).format("DD/MM/YYYY"),
    },
    {
      field: "left",
      headerName: "Restant",
      width: 250,
      renderCell: (params) => {
        return (
          <div>
            <span>{getDaysLeft(params.row.fields.Date).daysLeft} Jours</span>
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => {
        return (
          <div style={{ fontSize: "1.7rem" }}>
            <Link to={`/alerts/${params.row.id}/edit`}>
              <span style={{ color: "rgb(53,88,199)", paddingRight: "50%" }}>
                <AiFillEdit />
              </span>
            </Link>

            {isAdmin && (
              <span
                style={{ color: "rgba(255,77,77,1)", cursor: "pointer" }}
                onClick={() => {
                  handleDeleteAlert(params.row.id);
                }}
              >
                <MdDelete />
              </span>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <ToastContainer />
      <Loading isOn={isLoading} />
      <div
        className={`${
          !alerts || isLoading ? "button-loading" : "send-button list"
        }`}
      >
        <Link to="/alerts/add">
          <Button size="large" variant="contained">
            Ajouter
            <span className="send-button-icon">
              <AiFillPlusCircle />
            </span>
          </Button>
        </Link>
      </div>
      {alerts && !isLoading && (
        <>
          <Filter Alert data={alerts} setFilteredData={setFilteredData} />
          <Box
            sx={{
              height: "100%",
              width: "100%",
              ".Alert": {
                span: {
                  padding: "5px 10px",
                  borderRadius: "30px",
                  color: "white",
                  fontSize: "1rem",
                },
                "&.low": {
                  span: {
                    backgroundColor: "#70e000",
                  },
                },
                "&.medium": {
                  span: {
                    backgroundColor: "#f26419",
                  },
                },
                "&.high": {
                  span: {
                    backgroundColor: "#d90429",
                  },
                },
                "&.passed": {
                  span: {
                    backgroundColor: "#bcb8b1",
                  },
                },
              },
            }}
          >
            <DataGrid
              rows={filteredData || alerts}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              getCellClassName={(params) => {
                if (params.field === "left") {
                  const daysLeft = getDaysLeft(params.row.fields.Date).status;
                  if (daysLeft === "low") return "Alert low";
                  else if (daysLeft === "medium") return "Alert medium";
                  else if (daysLeft === "high") return "Alert high";
                  else return "Alert passed";
                }
              }}
            />
          </Box>
        </>
      )}
    </>
  );
}

export default AlertList;
