import TaskService from "./Task.services";
import VehiculeService from "./Vehicule.services";
import ClientService from "./Client.services";
import CircuitService from "./Circuit.services";
import MaintenanceService from "./Maintenance.services";
import ChauffeurService from "./Chauffeur.services";

async function getAllData(includeTasks = false) {
  let taskData = {};
  if (includeTasks) {
    taskData = await new TaskService().GetAll({
      fields: ["TaskStart", "TaskEnd", "vehicule", "chauffeur"],
    });
  }
  const vehiculeData = await new VehiculeService().GetAll();
  const clientData = await new ClientService().GetAll();
  const circuitData = await new CircuitService().GetAll();
  const maintenanceData = await new MaintenanceService().GetAll();
  const chauffeurData = await new ChauffeurService().GetAll();

  return {
    taskData: taskData.data,
    vehiculeData: vehiculeData.data,
    clientData: clientData.data,
    circuitData: circuitData.data,
    maintenanceData: maintenanceData.data,
    chauffeurData: chauffeurData.data,
  };
}

export { getAllData };
