import React from "react";

import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { TbArrowBarRight, TbArrowBarToLeft } from "react-icons/tb";
import {
  FaHourglassStart,
  FaHourglassEnd,
  FaRoute,
  FaReceipt,
  FaMoneyBillWave,
} from "react-icons/fa";
import { GiSteeringWheel, GiPriceTag } from "react-icons/gi";
import { AiOutlineFieldNumber, AiFillCloseCircle } from "react-icons/ai";
import { MdLocationOn, MdNumbers } from "react-icons/md";
import "./TaskCard.css";
import QuittanceTable from "./QuittanceTable";

const getBackgroundColor = (data) => {
  if (data === "payer") return { backgroundColor: "#70e000" };
  else if (data === "partial") return { backgroundColor: "#f26419" };
  else return { backgroundColor: "#d90429" };
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%) scale(.85)",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

function TaskCard({ taskData, closeModal }) {
  const quittance = JSON.parse(taskData.Quittance || "[]");
  const settings = JSON.parse(localStorage.getItem("Settings")).fields;

  return (
    <Box sx={style} position="relative">
      <span className="close-icon" onClick={() => closeModal()}>
        <AiFillCloseCircle />
      </span>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={6}>
          <span className="client-title">
            <span className="client-text">Client: </span>
            <span className="client-data">
              {taskData.NameClient}, {taskData.TelClient}
            </span>
          </span>
        </Grid>
        <Grid item xs={6}>
          <div className="payment-text">
            Payement:
            <div
              className="payment"
              style={getBackgroundColor(taskData.Payment)}
            >
              <span>{taskData.Payment}</span>
            </div>
          </div>
        </Grid>
      </Grid>

      <Divider />
      <Grid container spacing={1} columnSpacing={3}>
        <Grid item xs={6}>
          <span className="icon">
            <TbArrowBarRight />
          </span>
          <span className="title">Jour Departure</span>
          <p className="text">
            {dayjs(taskData.TaskStart).format("DD/MM/YYYY")}
          </p>
        </Grid>
        <Grid item xs={6}>
          <span className="icon">
            <TbArrowBarToLeft />
          </span>
          <span className="title">Jour Arrivé</span>
          <p className="text">{dayjs(taskData.TaskEnd).format("DD/MM/YYYY")}</p>
        </Grid>
        <Grid item xs={6}>
          <span className="icon">
            <FaHourglassStart />
          </span>
          <span className="title">Temps Departure</span>
          <p className="text">{dayjs(taskData.TaskStart).format("HH:mm")}</p>
        </Grid>
        <Grid item xs={6}>
          <span className="icon">
            <FaHourglassEnd />
          </span>
          <span className="title">Temps Arrivé</span>
          <p className="text">{dayjs(taskData.TaskEnd).format("HH:mm")}</p>
        </Grid>
        <Grid item xs={6}>
          <span className="icon">
            <AiOutlineFieldNumber />
          </span>
          <span className="title">Matricule</span>
          <p className="text">{taskData.NumMatricule}</p>
        </Grid>
        <Grid item xs={6}>
          <span className="icon">
            <GiSteeringWheel />
          </span>
          <span className="title">Chauffeur</span>
          <p className="text">{taskData.NameChauffeur}</p>
        </Grid>
        <Grid item xs={6}>
          <span className="icon">
            <FaRoute />
          </span>
          <span className="title">Trajet</span>
          <p className="text">{taskData.RouteName}</p>
        </Grid>
        <Grid item xs={6}>
          <span className="icon">
            <GiPriceTag />
          </span>
          <span className="title">Prix</span>
          <p className="text">
            {taskData.Prix || taskData.PriceCircuit || 0} {settings.currency}
          </p>
        </Grid>
        <Grid item xs={6}>
          <span className="icon">
            <MdNumbers />
          </span>
          <span className="title">Nombre de Places</span>
          <p className="text">{taskData.NPlaces || ""} Places</p>
        </Grid>
        <Grid item xs={6}>
          <span className="icon">
            <MdLocationOn />
          </span>
          <span className="title">Point de Ramassage</span>
          <p className="text">{taskData.PointRamassage || ""}</p>
        </Grid>

        <Grid item xs={6}>
          <span className="icon">
            <FaReceipt />
          </span>
          <span className="title">Quittance</span>
          <div className="text">
            <QuittanceTable quittance={quittance} />
          </div>
        </Grid>

        <Grid item xs={6}>
          <span className="icon">
            <FaMoneyBillWave />
          </span>
          <span className="title">Montant Reste a Payé</span>
          <p className="text">
            {parseInt(taskData.PriceCircuit) -
              quittance.reduce(
                (acc, currValue) => acc + parseInt(currValue.montant),
                0
              )}{" "}
            {settings.currency}
          </p>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TaskCard;
