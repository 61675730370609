import React from "react";
import { Link } from "react-router-dom";

import "./DashBox.css";

const DashBox = (props) => {
  const { Icon, title, number, backgroundColor, link } = props;
  return (
    <Link to={link}>
      <div className="summary-box" style={{ backgroundColor }}>
        <div className="box-icon">
          <Icon />
        </div>
        <div className="box-title">{title}</div>
        <div className="box-number">{number}</div>
      </div>
    </Link>
  );
};

export default DashBox;
