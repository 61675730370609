import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Filter from "../../components/Filter";
import Loading from "../../components/Loading";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { AiFillEdit, AiFillPlusCircle } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import Button from "@mui/material/Button";
import ChauffeurService from "../../services/Chauffeur.services";
import { ToastContainer, toast } from "react-toastify";
import ImageCard from "./ImageCard";

function ChauffeurList() {
  const [chauffeurs, setChauffeurs] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState({ url: "", title: "" });
  const [openModal, setOpenModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [filteredData, setFilteredData] = useState(null);

  const _chauffeurService = new ChauffeurService();
  const isAdmin = JSON.parse(localStorage.getItem("user")).Role === "admin";

  useEffect(() => {
    setIsLoading(true);
    _chauffeurService.GetAll().then((res) => {
      setChauffeurs(res.data);
      console.log(res.data);
      setIsLoading(false);
    });
  }, [reload]);

  const handleDeleteChauffeur = async (id) => {
    const foundChauffeur = chauffeurs.find((chauffeur) => chauffeur.id === id);
    if (foundChauffeur.fields.task) {
      toast.error("Chauffeur a deja des reservations", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      await _chauffeurService.Delete(id);
      setReload((prevState) => !prevState);
      toast.success("Chauffeur Supprimé!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleImageClick = (url, title) => {
    setImage({ url, title });
    setOpenModal(true);
  };

  const columns = [
    {
      field: "name",
      headerName: "Nom",
      type: "string",
      width: 200,
      valueGetter: (params) => {
        return params.row.fields.Name || "";
      },
    },
    {
      field: "numIdentite",
      headerName: "Num Carte Identité",
      width: 200,
      valueGetter: (params) => params.row.fields.NumIdentite || "",
    },
    {
      field: "numTel",
      headerName: "Numero Telephone",
      width: 200,
      valueGetter: (params) => params.row.fields.NumTel || "",
    },
    {
      field: "carteIdentité",
      headerName: "Photo Carte Identité",
      width: 200,
      renderCell: (params) => {
        return (
          params.row.fields.IdentitePhoto && (
            <img
              onClick={() =>
                handleImageClick(
                  params.row.fields.IdentitePhoto[0].url || "",
                  "Image Carte Identité"
                )
              }
              style={{ cursor: "pointer", width: 61, height: 36 }}
              src={params.row.fields.IdentitePhoto[0].url || ""}
              alt="image carte identite"
            />
          )
        );
      },
    },
    {
      field: "permis",
      headerName: "Photo Permis",
      width: 200,
      renderCell: (params) => {
        return (
          params.row.fields.PermisPhoto && (
            <img
              onClick={() =>
                handleImageClick(
                  params.row.fields.PermisPhoto[0].url,
                  "Image Permis"
                )
              }
              style={{ cursor: "pointer", width: 61, height: 36 }}
              src={params.row.fields.PermisPhoto[0].url}
              alt="image permis"
            />
          )
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => {
        return (
          <div style={{ fontSize: "1.7rem" }}>
            <Link to={`/chauffeurs/${params.row.id}/edit`}>
              <span style={{ color: "rgb(53,88,199)", paddingRight: "50%" }}>
                <AiFillEdit />
              </span>
            </Link>

            {isAdmin && (
              <span
                style={{ color: "rgba(255,77,77,1)", cursor: "pointer" }}
                onClick={() => {
                  handleDeleteChauffeur(params.row.id);
                }}
              >
                <MdDelete />
              </span>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Loading isOn={isLoading} />
      <ToastContainer />
      <ImageCard
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
        url={image.url}
        title={image.title}
      />
      <div
        className={`${
          !chauffeurs || isLoading ? "button-loading" : "send-button list"
        }`}
      >
        <Link to="/chauffeurs/add">
          <Button size="large" variant="contained">
            Ajouter
            <span className="send-button-icon">
              <AiFillPlusCircle />
            </span>
          </Button>
        </Link>
      </div>
      {chauffeurs && !isLoading && (
        <>
          <Filter
            Chauffeur
            data={chauffeurs}
            setFilteredData={setFilteredData}
          />
          <Box
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            <DataGrid
              rows={filteredData || chauffeurs}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
            />
          </Box>
        </>
      )}
    </>
  );
}

export default ChauffeurList;
