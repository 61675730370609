import React, { useEffect, useState } from "react";

import TaskCard from "../Tasks/TaskCard";
import Loading from "../../components/Loading";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import Modal from "@mui/material/Modal";
import TaskService from "../../services/Task.services";

const getBackgroundColor = (data) => {
   
  if (data === "payer") return "#70e000";
  else if (data === "partial") return "#f26419";
  else if(data==="Anuuler") return "#ef6714"; else return "#d90429";
};

const findTask = (id, tasks) => {
  const foundTask = tasks.find((task) => task.id === id);
  return foundTask.fields;
};

function Calendar() {
  const [tasks, setTasks] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [taskToView, setTaskToView] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const _taskService = new TaskService();
      const res = await _taskService.GetAll();
      setTasks(res.data);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  let events;
  if (tasks) {
    events = tasks.map((task) => {
      return {
        id: task.id,
        title: task.fields.NameClient || "titre",
        start: task.fields.TaskStart,
        end: task.fields.TaskEnd,
        color: getBackgroundColor(task.fields.Payment),
        editable: true,
      };
    });
  }

  const handleOpenModal = (id) => {
    setOpenModal(true);
    setTaskToView(findTask(id, tasks));
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setTaskToView(null);
  };

  return (
    <>
      <Loading isOn={isLoading} />
      {taskToView && (
        <Modal open={openModal} onClose={handleCloseModal}>
          <div>
            <TaskCard taskData={taskToView} closeModal={handleCloseModal} />
          </div>
        </Modal>
      )}
      {tasks && (
        <FullCalendar
          headerToolbar={{
            left: "prev,next",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          plugins={[dayGridPlugin, timeGridPlugin]}
          events={events}
          eventClick={(info) => handleOpenModal(info.event.id)}
          locale="fr"
        />
      )}
    </>
  );
}

export default Calendar;
