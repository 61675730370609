import request from "../configs";

export default class UploadService {
  constructor() {
    this.request = request;
    this.userData = this.getUserData();
    this.headers = {
      Authorization: `Bearer ${this.userData.AIRTABLEAPI}`,
      "content-type": "application/json",
    };
  }

  getUserData() {
    return JSON.parse(localStorage.getItem("user"));
  }

  uploadFileToServeur = async (file) => {
    const url = "http://upload.awslabs.tn/Upload.php";

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    // Extract the base64-encoded image data from the data URL
    const base64Image = file.split(",")[1];
    const formData = new FormData();
    formData.append("image", base64Image);
    formData.append("image_type", "jpg");

    return await this.request.post(url, formData, { headers });
  };

  upload(file, func) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = async () => {
      const res = await this.uploadFileToServeur(fileReader.result);
      func(res.data.image_url);
    };
  }
}
