import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Filter from "../../components/Filter";
import Loading from "../../components/Loading";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { AiFillEdit, AiFillPlusCircle } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import VehiculeService from "../../services/Vehicule.services";
import AlertService from "../../services/Alert.services";
import MaintenanceService from "../../services/Maintenance.services";

function VehiculeList() {
  const [vehicules, setVehicules] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [filteredData, setFilteredData] = useState(null);

  const _vehiculeService = new VehiculeService();
  const _alertService = new AlertService();
  const _maintenanceService = new MaintenanceService();
  const isAdmin = JSON.parse(localStorage.getItem("user")).Role === "admin";

  useEffect(() => {
    setIsLoading(true);
    _vehiculeService.GetAll().then((res) => {
      setVehicules(res.data);
      console.log(res.data);
      setIsLoading(false);
    });
  }, [reload]);

  const handleDeleteAlert = async (id) => {
    const foundVehicule = vehicules.find((vehicule) => vehicule.id === id);
    if (foundVehicule.fields.task) {
      toast.error("Vehicule a deja des reservations", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setIsLoading(true);

      await _vehiculeService.Delete(id);

      if (foundVehicule.fields.Alerts) {
        for (const alert of foundVehicule.fields.Alerts) {
          await _alertService.Delete(alert);
        }
      }

      if (foundVehicule.fields.maintenance) {
        for (const m of foundVehicule.fields.maintenance) {
          await _maintenanceService.Delete(m);
        }
      }
      // debugger;
      setIsLoading(false);
      setReload((prevState) => !prevState);
      toast.success("Vehicule Supprimé!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const columns = [
    {
      field: "matricule",
      headerName: "Matricule",
      width: 300,
      valueGetter: (params) => {
        return params.row.fields.NumMatricule;
      },
    },
    {
      field: "type",
      headerName: "Type",
      width: 300,
      type: "String",
      valueGetter: (params) => {
        return params.row.fields.Type;
      },
    },
    {
      field: "marque",
      headerName: "Marque",
      type: "string",
      width: 200,
      valueGetter: (params) => params.row.fields.Marque,
    },
    {
      field: "modele",
      headerName: "Modele",
      width: 250,
      valueGetter: (params) => params.row.fields.Modele,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => {
        return (
          <div style={{ fontSize: "1.7rem" }}>
            <Link to={`/vehicules/${params.row.id}/edit`}>
              <span style={{ color: "rgb(53,88,199)", paddingRight: "50%" }}>
                <AiFillEdit />
              </span>
            </Link>

            {isAdmin && (
              <span
                style={{ color: "rgba(255,77,77,1)", cursor: "pointer" }}
                onClick={() => {
                  handleDeleteAlert(params.row.id, params.row.NumMatricule);
                }}
              >
                <MdDelete />
              </span>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Loading isOn={isLoading} />
      <ToastContainer />
      <div
        className={`${
          !vehicules || isLoading ? "button-loading" : "send-button list"
        }`}
      >
        <Link to="/vehicules/add">
          <Button size="large" variant="contained">
            Ajouter
            <span className="send-button-icon">
              <AiFillPlusCircle />
            </span>
          </Button>
        </Link>
      </div>
      {vehicules && !isLoading && (
        <>
          <Filter Vehicule data={vehicules} setFilteredData={setFilteredData} />
          <Box
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            <DataGrid
              rows={filteredData || vehicules}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
            />
          </Box>
        </>
      )}
    </>
  );
}

export default VehiculeList;
