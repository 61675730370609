import request from "../configs";

export default class SettingService {
  constructor() {
    this.request = request;
    this.userData = this.getUserData();
    this.headers = {
      Authorization: `Bearer ${this.userData.AIRTABLEAPI}`,
      "content-type": "application/json",
    };
  }

  getUserData() {
    return JSON.parse(localStorage.getItem("user"));
  }

  async GetAll(params = {}) {
    const headers = this.headers;
    let res = await this.request.get(
      `https://api.airtable.com/v0/${this.userData.DATBASENAME}/Setting`,
      { headers, params }
    );

    let data = [...res.data.records];
    while (res.data.offset) {
      res = await this.GetMore(res.data.offset);
      data = [...data, ...res.data.records];
    }
    return { data };
  }

  GetMore(offset) {
    const headers = this.headers;
    return this.request.get(
      `https://api.airtable.com/v0/${this.userData.DATBASENAME}/Setting?&offset=${offset}`,
      { headers }
    );
  }

  GetById(id) {
    const headers = this.headers;
    return this.request.get(
      `https://api.airtable.com/v0/${this.userData.DATBASENAME}/Setting/${id}`,
      { headers }
    );
  }

  Edit(body) {
    const headers = this.headers;
    return this.request.patch(
      `https://api.airtable.com/v0/${this.userData.DATBASENAME}/Setting`,
      body,
      { headers }
    );
  }

  Add(body) {
    const headers = this.headers;
    return this.request.post(
      `https://api.airtable.com/v0/${this.userData.DATBASENAME}/Setting`,
      body,
      { headers }
    );
  }

  Delete(id) {
    const headers = this.headers;
    return this.request.delete(
      `https://api.airtable.com/v0/${this.userData.DATBASENAME}/Setting?&records[]=${id}`,
      {
        headers: {
          ...headers,
          "content-type": "application/x-www-form-urlencoded",
        },
      }
    );
  }
}
