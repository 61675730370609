import React, { useRef } from "react";

import TextField from "@mui/material/TextField";
import { Box, Modal, Grid, Button } from "@mui/material";
import { IoIosSave } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";

const styles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 800,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

function QuittanceCard({ open, onClose, quittance }) {
  const numRef = useRef();
  const montantRef = useRef();

  const handleSubmit = () => {
    quittance((prevState) => [
      ...prevState,
      { numero: numRef.current.value, montant: montantRef.current.value },
    ]);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={styles}>
        <span className="close-icon" onClick={onClose}>
          <AiFillCloseCircle />
        </span>
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <label className="label">Numero</label>
            <TextField inputRef={numRef} id="outlined-number" label="Numero" />
          </Grid>
          <Grid item xs={4}>
            <label className="label">Montant Payé</label>
            <TextField
              inputRef={montantRef}
              id="outlined-number"
              label="Montant"
              type="number"
            />
          </Grid>
        </Grid>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="large"
            variant="contained"
            onClick={handleSubmit}
            endIcon={<IoIosSave />}
          >
            Enregistrer
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default QuittanceCard;
