import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Filter from "../../components/Filter";
import TaskCard from "./TaskCard";
import MissionOrder from "./MissionOrder";
import Loading from "../../components/Loading";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import {
  AiFillEdit,
  AiOutlineExpandAlt,
  AiFillPlusCircle,
  AiOutlineCloudDownload,
} from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import TaskService from "../../services/Task.services";
import { ToastContainer, toast } from "react-toastify";
import "./TaskList.css";

const TaskList = () => {
  const [tasks, setTasks] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [taskToView, setTaskToView] = useState(null);
  const [reload, setReload] = useState(false);
  const [missionOrder, setMissionOrder] = useState(false);
  const [filteredData, setFilteredData] = useState(null);

  const _taskService = new TaskService();
  const isAdmin = JSON.parse(localStorage.getItem("user")).Role === "admin";
  const settings = JSON.parse(localStorage.getItem("Settings")).fields;

  useEffect(() => {
    setIsLoading(true);
    _taskService.GetAll({
       
      
  
      "sort[0][field]": "TaskStart",
      "sort[0][direction]": "desc",
    }).then((res) => {
      setTasks(res.data);
      console.log(res.data);
      setIsLoading(false);
    });
  }, [reload]);

  const toggleMissionOrder = () => {
    setMissionOrder((prevState) => !prevState);
  };

  const toggleOpenModal = () => {
    setOpenModal((prevState) => {
      setTaskToView(null);
      return !prevState;
    });
  };

  const handleDeleteTask = async (id) => {
    setIsLoading(true);
    await _taskService.Delete(id);
    setReload((prevState) => !prevState);
    toast.success("Reservation Supprimé.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const columns = [
    {
      field: "taskStart",
      headerName: "Début",
      width: 200,
      
      valueGetter: (params) => {
        return `${dayjs(params.row.fields.TaskStart).format(
          "DD/MM/YYYY HH:mm"
        )}`;
      },
    },
    {
      field: "taskEnd",
      headerName: "Fin",
      width: 200,
      valueGetter: (params) => {
        return `${dayjs(params.row.fields.TaskEnd).format("DD/MM/YYYY HH:mm")}`;
      },
    },
    {
      field: "matricule",
      headerName: "Matricule",
      width: 150,
      valueGetter: (params) => `${params.row.fields.NumMatricule || ""}`,
    },
    {
      field: "circuit",
      headerName: "Circuit",
      type: "string",
      width: 300,
      valueGetter: (params) => `${params.row.fields.RouteName || ""}`,
    },
    {
      field: "price",
      headerName: "Prix",
      width: 100,
      valueGetter: (params) =>
        `${params.row.fields.Prix || params.row.fields.PriceCircuit || 0} ${
          settings.currency
        }`,
    },
    {
      field: "payment",
      headerName: "Payement",
      width: 130,
      type: "string",
      renderCell: (params) => {
        return (
          <div>
            <span>{params.row.fields.Payment}</span>
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="actions-container">
            <span
              style={{ color: "rgb(84,175,101)", cursor: "pointer" }}
              onClick={() => {
                toggleOpenModal();
                setTaskToView(params.row.fields);
              }}
            >
              <AiOutlineExpandAlt />
            </span>
            {isAdmin && (
              <Link to={`/tasks/${params.row.id}/edit`}>
              <span style={{ color: "rgb(53,88,199)" }}>
                <AiFillEdit />
              </span>
            </Link>
            )}
           

            {isAdmin && (
              <span
                style={{ color: "rgba(255,77,77,1)", cursor: "pointer" }}
                onClick={() => {
                  handleDeleteTask(params.row.id);
                }}
              >
                <MdDelete />
              </span>
            )}

            <span
              style={{ color: "rgb(230,128,25)", cursor: "pointer" }}
              onClick={() => {
                setTaskToView(params.row.fields);
                toggleMissionOrder();
              }}
            >
              <AiOutlineCloudDownload />
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Loading isOn={isLoading} />
      <ToastContainer />
      <div
        className={`${
          !tasks || isLoading ? "button-loading" : "send-button list"
        }`}
      >
        <Link to="/tasks/add">
          <Button size="large" variant="contained">
            Ajouter
            <span className="send-button-icon">
              <AiFillPlusCircle />
            </span>
          </Button>
        </Link>
      </div>
      <Modal open={openModal} onClose={toggleOpenModal}>
        <div>
          {taskToView !== null ? (
            <TaskCard taskData={taskToView} closeModal={toggleOpenModal} />
          ) : (
            ""
          )}
        </div>
      </Modal>
      <MissionOrder
        taskData={taskToView}
        open={missionOrder}
        close={toggleMissionOrder}
      />
      {tasks && !isLoading && (
        <>
          <Filter Task data={tasks} setFilteredData={setFilteredData} />
          <Box
            sx={{
              height: 630,
              width: "100%",
              ".Payment": {
                span: {
                  padding: "5px 10px",
                  borderRadius: "30px",
                  color: "white",
                  fontSize: "1rem",
                },
                "&.payer": {
                  span: {
                    backgroundColor: "#70e000",
                  },
                },
                "&.partielle": {
                  span: {
                    backgroundColor: "#f26419",
                  },
                },
                "&.nonPayer": {
                  span: {
                    backgroundColor: "#d90429",
                  },
                },
              },
            }}
          >
            <DataGrid
              rows={filteredData || tasks}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 100,
                  },
                },
              }}
              pageSizeOptions={[100]}
              getCellClassName={(params) => {
                if (params.field === "payment") {
                  if (params.row.fields.Payment === "payer")
                    return "Payment payer";
                  else if (params.row.fields.Payment === "partial")
                    return "Payment partielle";
                  else return "Payment nonPayer";
                }
              }}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default TaskList;
