import Login from "../../screens/Auth/Login";
import Dashboard from "../../screens/dashboard";
import Calendar from "../../screens/Calendar";
import TaskList from "../../screens/Tasks/TaskList";
import UpdateTask from "../../screens/Tasks/UpdateTask";
import AddTask from "../../screens/Tasks/AddTask";
import AlertList from "../../screens/Alerts/AlertList";
import UpdateAlert from "../../screens/Alerts/UpdateAlert";
import AddAlert from "../../screens/Alerts/AddAlert";
import VehiculeList from "../../screens/Vehicules/VehiculeList";
import AddVehicule from "../../screens/Vehicules/AddVehicule";
import UpdateVehicule from "../../screens/Vehicules/UpdateVehicule";
import ClientList from "../../screens/Clients/ClientList";
import AddClient from "../../screens/Clients/AddClient";
import UpdateClient from "../../screens/Clients/UpdateClient";
import CircuitList from "../../screens/Circuits/CircuitList";
import AddCircuit from "../../screens/Circuits/AddCircuit";
import UpdateCircuit from "../../screens/Circuits/UpdateCircuit";
import MaintenanceList from "../../screens/Maintenance/MaintenanceList";
import AddMaintenance from "../../screens/Maintenance/AddMaintenance";
import UpdateMaintenance from "../../screens/Maintenance/UpdateMaintenance";
import ChauffeurList from "../../screens/Chauffeur/ChauffeurList";
import AddChauffeur from "../../screens/Chauffeur/AddChauffeur";
import UpdateChauffeur from "../../screens/Chauffeur/UpdateChauffeur";

export const routes = [
  {
    title: "Dashboard",
    path: "/",
    component: Dashboard,
    isPublic: false,
    exact: true,
  },
  {
    title: "Calendrier",
    path: "/calendar",
    component: Calendar,
    isPublic: false,
    exact: true,
  },
  {
    title: "Login",
    path: "/login",
    component: Login,
    isPublic: true,
    exact: true,
  },
  {
    title: "Reservations",
    path: "/tasks",
    component: TaskList,
    isPublic: false,
    exact: true,
  },
  {
    title: "Ajouter Reservation",
    path: "/tasks/add",
    component: AddTask,
    isPublic: false,
    exact: true,
  },
  {
    title: "Modifier Reservation",
    path: "/tasks/:id/edit",
    component: UpdateTask,
    isPublic: false,
    exact: true,
  },
  {
    title_ar: "",
    title: "Alerts",
    path: "/alerts",
    component: AlertList,
    isPublic: false,
    exact: true,
  },
  {
    title: "Ajouter Alert",
    path: "/alerts/add",
    component: AddAlert,
    isPublic: false,
    exact: true,
  },
  {
    title: "Modifier Alert",
    path: "/alerts/:id/edit",
    component: UpdateAlert,
    isPublic: false,
    exact: true,
  },
  {
    title: "Vehicules",
    path: "/vehicules",
    component: VehiculeList,
    isPublic: false,
    exact: true,
  },
  {
    title: "Ajouter Vehicule",
    path: "/vehicules/add",
    component: AddVehicule,
    isPublic: false,
    exact: true,
  },
  {
    title: "Modifier Vehicule",
    path: "/vehicules/:id/edit",
    component: UpdateVehicule,
    isPublic: false,
    exact: true,
  },
  {
    title: "Clients",
    path: "/clients",
    component: ClientList,
    isPublic: false,
    exact: true,
  },
  {
    title: "Ajouter Client",
    path: "/clients/add",
    component: AddClient,
    isPublic: false,
    exact: true,
  },
  {
    title: "Modifier Client",
    path: "/clients/:id/edit",
    component: UpdateClient,
    isPublic: false,
    exact: true,
  },
  {
    title: "Missions",
    path: "/circuits",
    component: CircuitList,
    isPublic: false,
    exact: true,
  },
  {
    title: "Ajouter Mission",
    path: "/circuits/add",
    component: AddCircuit,
    isPublic: false,
    exact: true,
  },
  {
    title: "Modifier Mission",
    path: "/circuits/:id/edit",
    component: UpdateCircuit,
    isPublic: false,
    exact: true,
  },
  {
    title: "Maintenances",
    path: "/maintenances",
    component: MaintenanceList,
    isPublic: false,
    exact: true,
  },
  {
    title: "Ajouter Maintenance",
    path: "/maintenances/add",
    component: AddMaintenance,
    isPublic: false,
    exact: true,
  },
  {
    title: "Modifier Maintenance",
    path: "/maintenances/:id/edit",
    component: UpdateMaintenance,
    isPublic: false,
    exact: true,
  },
  {
    title: "Chauffeurs",
    path: "/chauffeurs",
    component: ChauffeurList,
    isPublic: false,
    exact: true,
  },
  {
    title: "Ajouter Chauffeur",
    path: "/chauffeurs/add",
    component: AddChauffeur,
    isPublic: false,
    exact: true,
  },
  {
    title: "Modifier Chauffeur",
    path: "/chauffeurs/:id/edit",
    component: UpdateChauffeur,
    isPublic: false,
    exact: true,
  },
];
