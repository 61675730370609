import React, { useEffect, useState, useReducer } from "react";
import { useHistory } from "react-router-dom";

import Loading from "../../components/Loading";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import VehiculeService from "../../services/Vehicule.services";
import Button from "@mui/material/Button";
import { IoIosSave } from "react-icons/io";
import { toast } from "react-toastify";

function AddVehicule() {
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const history = useHistory();

  const checkValidity = (state) => {
    if (state.fields.Marque.length <= 0) return false;
    if (state.fields.NumMatricule.length <= 0) return false;
    if (state.fields.Type.length <= 0) return false;
    return true;
  };

  const inputReducer = (state, action) => {
    let newState = {};
    if (action.type === "MATRICULE") {
      newState = {
        ...state,
        fields: { ...state.fields, NumMatricule: action.payload },
      };
    }

    if (action.type === "TYPE") {
      newState = {
        ...state,
        fields: { ...state.fields, Type: action.payload },
      };
    }

    if (action.type === "MARQUE") {
      newState = {
        ...state,
        fields: { ...state.fields, Marque: action.payload },
      };
    }

    if (action.type === "MODELE") {
      newState = {
        ...state,
        fields: { ...state.fields, Modele: action.payload },
      };
    }

    setIsValid(checkValidity(newState));
    return newState;
  };

  const [vehiculeData, dispatchVehiculeData] = useReducer(inputReducer, {
    fields: {
      NumMatricule: "",
      Type: "",
      Marque: "",
      Modele: "",
      Dashboard: ["recGjaAORvBLIJBe7"],
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const body = JSON.stringify({
      records: [vehiculeData],
    });
    const _vehiculeService = new VehiculeService();
    await _vehiculeService.Add(body);
    setIsLoading(false);
    history.push(`/vehicules`);
    toast.success("Vehicule Ajouté!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <>
      <Loading isOn={isLoading} />
      {!isLoading && (
        <form className="form">
          <Grid container spacing={2}>
            <Grid container item spacing={2}>
              <Grid item xs={4}>
                <label className="label">Matricule</label>
                <TextField
                  fullWidth
                  id="outlined-number"
                  label="Matricule"
                  type="text"
                  value={vehiculeData.fields.NumMatricule}
                  onChange={(e) =>
                    dispatchVehiculeData({
                      type: "MATRICULE",
                      payload: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={4}>
                <label className="label">Type</label>
                <FormControl fullWidth>
                  <InputLabel id="type-id">Type</InputLabel>
                  <Select
                    labelId="type-id"
                    id="type"
                    value={vehiculeData.fields.Type}
                    label="type"
                    onChange={(e) =>
                      dispatchVehiculeData({
                        type: "TYPE",
                        payload: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="bus">Bus</MenuItem>
                    <MenuItem value="4*4">4*4</MenuItem>
                    <MenuItem value="minibus">Mini Bus</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container item spacing={2}>
              <Grid item xs={4}>
                <label className="label">Marque</label>
                <TextField
                  fullWidth
                  id="outlined-number"
                  label="Marque"
                  type="text"
                  value={vehiculeData.fields.Marque}
                  onChange={(e) =>
                    dispatchVehiculeData({
                      type: "MARQUE",
                      payload: e.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={4}>
                <label className="label">Modele</label>
                <TextField
                  fullWidth
                  id="outlined-number"
                  label="Modele"
                  type="text"
                  value={vehiculeData.fields.Modele}
                  onChange={(e) =>
                    dispatchVehiculeData({
                      type: "MODELE",
                      payload: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}

      <div className={`${isLoading ? "button-loading" : "send-button"}`}>
        <Button
          size="large"
          variant="contained"
          onClick={handleSubmit}
          endIcon={<IoIosSave />}
          disabled={!isValid}
        >
          Enregistrer
        </Button>
      </div>
    </>
  );
}

export default AddVehicule;
