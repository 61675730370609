import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Filter from "../../components/Filter";
import Loading from "../../components/Loading";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { AiFillEdit, AiFillPlusCircle } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import Button from "@mui/material/Button";
import MaintenanceService from "../../services/Maintenance.services";
import { ToastContainer, toast } from "react-toastify";
import NoteCard from "./NoteCard";
import "./MaintenanceList.css";

function MaintenanceList() {
  const [maintenances, setMaintenances] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [note, setNote] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [filteredData, setFilteredData] = useState(null);

  const _maintenanceService = new MaintenanceService();
  const isAdmin = JSON.parse(localStorage.getItem("user")).Role === "admin";
  const settings = JSON.parse(localStorage.getItem("Settings")).fields;

  useEffect(() => {
    setIsLoading(true);
    _maintenanceService.GetAll().then((res) => {
      setMaintenances(res.data);
      console.log(res.data);
      setIsLoading(false);
    });
  }, [reload]);

  const handleDeleteMaintenance = async (id) => {
    await _maintenanceService.Delete(id);
    setReload((prevState) => !prevState);
    toast.success("Maintenance Supprimé!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const handleCellClick = (note) => {
    setNote(note);
    setOpenModal(true);
  };

  const columns = [
    {
      field: "vehicule",
      headerName: "Vehicule",
      width: 200,
      valueGetter: (params) => {
        return params.row.fields.NumMatricule;
      },
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      valueGetter: (params) => {
        return params.row.fields.Type;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      valueGetter: (params) =>
        dayjs(params.row.fields.Date).format("DD/MM/YYYY"),
    },
    {
      field: "price",
      headerName: "Prix",
      width: 150,
      valueGetter: (params) =>
        `${params.row.fields.Price || 0} ${settings.currency}`,
    },
    {
      field: "note",
      headerName: "Note",
      width: 250,
      renderCell: (params) => (
        <div className="maintenance-note">
          <p className="maintenance-note-p">{params.row.fields.Note}</p>
          {params.row.fields.Note && (
            <div
              className="maintenance-note-read-more-button"
              onClick={() => handleCellClick(params.row.fields.Note)}
            >
              Voir plus
            </div>
          )}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => {
        return (
          <div style={{ fontSize: "1.7rem" }}>
            <Link to={`/maintenances/${params.row.id}/edit`}>
              <span style={{ color: "rgb(53,88,199)", paddingRight: "50%" }}>
                <AiFillEdit />
              </span>
            </Link>

            {isAdmin && (
              <span
                style={{ color: "rgba(255,77,77,1)", cursor: "pointer" }}
                onClick={() => {
                  handleDeleteMaintenance(params.row.id);
                }}
              >
                <MdDelete />
              </span>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Loading isOn={isLoading} />
      <ToastContainer />
      <NoteCard
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
        note={note}
      />
      <div
        className={`${
          !maintenances || isLoading ? "button-loading" : "send-button list"
        }`}
      >
        <Link to="/maintenances/add">
          <Button size="large" variant="contained">
            Ajouter
            <span className="send-button-icon">
              <AiFillPlusCircle />
            </span>
          </Button>
        </Link>
      </div>
      {maintenances && !isLoading && (
        <>
          <Filter
            Maintenance
            data={maintenances}
            setFilteredData={setFilteredData}
          />
          <Box
            sx={{
              height: "100%",
              width: "100%",
            }}
          >
            <DataGrid
              rows={filteredData || maintenances}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
            />
          </Box>
        </>
      )}
    </>
  );
}

export default MaintenanceList;
