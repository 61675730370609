import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function CustomBarChart(props) {
  return (
    <div style={{ width: "700px", height: 300 }}>
      <ResponsiveContainer>
        <BarChart
          width={500}
          height={300}
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: -20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Payer" fill="#5bba6f" />
          <Bar dataKey="Partielle" fill="#f5b700" />
          <Bar dataKey="Non Payer" fill="#dd1c1a" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
