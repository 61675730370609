import React, { useEffect, useState, useReducer } from "react";
import { useHistory, useParams } from "react-router-dom";

import Loading from "../../components/Loading";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import CircuitService from "../../services/Circuit.services";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { IoIosSave } from "react-icons/io";

function UpdateCircuit() {
  const { id: circuitId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const history = useHistory();

  const isAdmin = JSON.parse(localStorage.getItem("user")).Role === "admin";

  const checkValidity = (state) => {
    if (state.fields.trajet.length == 0) return false;
    if (state.fields.Price <= 0) return false;
    return true;
  };

  const inputReducer = (state, action) => {
    let newState = {};
    if (action.type === "FETCHED") {
      return action.payload;
    }

    if (action.type === "TRAJET") {
      newState = {
        ...state,
        fields: { ...state.fields, Trajet: action.payload },
      };
    }

    if (action.type === "PRICE") {
      newState = {
        ...state,
        fields: { ...state.fields, Price: parseInt(action.payload) },
      };
    }

    setIsValid(checkValidity(newState));
    return newState;
  };

  const [circuitData, dispatchCircuitData] = useReducer(inputReducer, null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const _circuitService = new CircuitService();
      const res = await _circuitService.GetById(circuitId);
      dispatchCircuitData({ type: "FETCHED", payload: res.data });
      console.log(res.data);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const body = JSON.stringify({
      records: [
        {
          id: circuitData.id,
          fields: {
            ...circuitData.fields,
            totaltask: undefined,
            totalprice: undefined,
          },
        },
      ],
    });
    const _circuitService = new CircuitService();
    await _circuitService.Edit(body);
    setIsLoading(false);
    history.push(`/circuits`);
    toast.success("Circuit Modifié!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <>
      <Loading isOn={isLoading} />
      {circuitData && !isLoading && (
        <form className="form">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <label className="label">Trajet</label>
              <TextField
                label="Trajet"
                type="text"
                fullWidth
                value={circuitData.fields.trajet}
                onChange={(e) =>
                  dispatchCircuitData({
                    type: "TRAJET",
                    payload: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={4}>
              <label className="label">Prix de base</label>
              <TextField
                fullWidth
                label="Prix"
                type="number"
                disabled={!isAdmin}
                value={circuitData.fields.Price}
                onChange={(e) =>
                  dispatchCircuitData({
                    type: "PRICE",
                    payload: e.target.value,
                  })
                }
              />
            </Grid>
          </Grid>
        </form>
      )}

      <div className={`${isLoading ? "button-loading" : "send-button"}`}>
        <Button
          size="large"
          variant="contained"
          onClick={handleSubmit}
          endIcon={<IoIosSave />}
          disabled={!isValid}
        >
          Enregistrer
        </Button>
      </div>
    </>
  );
}

export default UpdateCircuit;
