import React from "react";

import loader from "../screens/Common/loader.gif";

const Loading = ({ isOn }) => {
  return (
    <div
      className="loading"
      style={isOn !== true ? { display: "none" } : { display: "" }}
    >
      <img src={loader} alt="loader"></img>
    </div>
  );
};

export default Loading;
