import React, { useState } from "react";

export const Btn = (props) => {
  const [dis, setdis] = useState(false);
  const { bg, rounded, loading = true } = props;
  return (
    <button
      onClickCapture={(e) => {
        if (loading) {
          setdis(true);
          setTimeout(() => setdis(false), 2000);
        }
      }}
      disabled={dis}
      style={{
        boxShadow: " 0px 2px 22px -14px rgba(0,0,0,0.75)",

        height: "35px",
        lineHeight: "35px",
        padding: "0 10px",
        border: "none",
        background:
          bg === "red"
            ? "rgba(255,77,77,1)"
            : bg === "blue"
            ? "rgb(53,88,199)"
            : bg === "green"
            ? "rgb(84,175,101)"
            : bg === "yellow"
            ? "rgb(236,182,28)"
            : bg === "orange"
            ? "rgb(230,128,25)"
            : bg === "gray"
            ? "rgb(178,180,188)"
            : bg === "black"
            ? "rgb(32,31,43)"
            : bg === "red2"
            ? "#F33155"
            : bg === "violet"
            ? "#7460EE"
            : bg === "green2"
            ? "#7ACE4C"
            : "#fff",
        color: props.dark || !bg ? "rgb(32,31,43)" : "#fff",
        borderRadius: rounded ? "18px" : "3px",
        textAlign: "center",
        margin: props.margin ? props.margin : "5px",
      }}
      {...props}
    >
      {!dis ? (
        props.children
      ) : (
        <img src="../images/Rolling.svg" alt="rolling" width="25px" />
      )}
    </button>
  );
};

export const OutlinedBtn = (props) => {
  const { color, rounded, style } = props;
  return (
    <button
      {...props}
      style={{
        boxShadow: " 0px 2px 22px -14px rgba(0,0,0,0.75)",

        height: "35px",
        lineHeight: "35px",
        padding: "0 10px",

        border:
          "1px solid " +
          (color === "red"
            ? "rgba(255,77,77,1)"
            : color === "blue"
            ? "rgb(53,88,199)"
            : color === "green"
            ? "rgb(84,175,101)"
            : color === "yellow"
            ? "rgb(236,182,28)"
            : color === "orange"
            ? "rgb(230,128,25)"
            : color === "gray"
            ? "rgb(178,180,188)"
            : color === "black"
            ? "rgb(32,31,43)"
            : "#fff"),
        color:
          color === "red"
            ? "rgba(255,77,77,1)"
            : color === "blue"
            ? "rgb(53,88,199)"
            : color === "green"
            ? "rgb(84,175,101)"
            : color === "yellow"
            ? "rgb(236,182,28)"
            : color === "orange"
            ? "rgb(230,128,25)"
            : color === "gray"
            ? "rgb(178,180,188)"
            : color === "black"
            ? "rgb(32,31,43)"
            : "#fff",
        borderRadius: rounded ? "18px" : "3px",
        margin: props.margin ? props.margin : "5px",
        background: "#fff",

        ...style,
      }}
    >
      {props.children}
    </button>
  );
};

export const BtnOpacity = (props) => {
  const { color, rounded } = props;
  return (
    <button
      style={{
        boxShadow: " 0px 2px 22px -14px rgba(0,0,0,0.75)",
        height: "35px",
        lineHeight: "35px",
        padding: "0 10px",

        background:
          color === "red"
            ? "rgba(255,77,77,0.2)"
            : color === "blue"
            ? "rgba(53,88,199,0.2)"
            : color === "green"
            ? "rgba(84,175,101,0.2)"
            : color === "yellow"
            ? "rgba(236,182,28,0.2)"
            : color === "orange"
            ? "rgba(230,128,25,0.2)"
            : color === "gray"
            ? "rgba(178,180,188,0.2)"
            : color === "black"
            ? "rgba(32,31,43,0.2)"
            : "#fff",
        color:
          color === "red"
            ? "rgba(255,77,77,1)"
            : color === "blue"
            ? "rgb(53,88,199)"
            : color === "green"
            ? "rgb(84,175,101)"
            : color === "yellow"
            ? "rgb(236,182,28)"
            : color === "orange"
            ? "rgb(230,128,25)"
            : color === "gray"
            ? "rgb(178,180,188)"
            : color === "black"
            ? "rgb(32,31,43)"
            : "#fff",
        borderRadius: rounded ? "18px" : "3px",
        margin: props.margin ? props.margin : "5px",
        border: 0,
      }}
      {...props}
    >
      {props.children}
    </button>
  );
};

export const AwsomeBtn = (props) => {
  const { bg, rounded } = props;
  return (
    <button
      style={{
        width: props.width ? props.width : "auto",
        height: props.height ? props.height + "px" : "35px",
        border: "4px  solid ",
        borderColor:
          bg === "red"
            ? "rgba(255,77,77,0.3)"
            : bg === "blue"
            ? "rgba(53,88,199,.3)"
            : bg === "green"
            ? "rgba(84,175,101,0.3)"
            : bg === "yellow"
            ? "rgba(236,182,28,0.3)"
            : bg === "orange"
            ? "rgba(230,128,25,0.3)"
            : bg === "gray"
            ? "rgba(178,180,188,0.3)"
            : bg === "black"
            ? "rgba(32,31,43,0.3)"
            : "#fff",
        // boxShadow: " 0px 2px 22px -14px rgba(0,0,0,0.75)",
        borderRadius: rounded ? "18px" : "3px",
        cursor: "pointer",
        padding: 0,
        margin: props.margin ? props.margin : "5px",
      }}
      {...props}
    >
      <div
        style={{
          height: props.height ? props.height - 6 + "px" : "29px",
          lineHeight: props.height ? props.height - 5 + "px" : "30px",
          padding: "0 10px",
          width: "calc(100% + 2px)",
          transform: "translate(-1px,-1px)",

          margin: 0,
          background:
            bg === "red"
              ? "rgba(255,77,77,1)"
              : bg === "blue"
              ? "rgb(53,88,199,1)"
              : bg === "green"
              ? "rgb(84,175,101)"
              : bg === "yellow"
              ? "rgb(236,182,28)"
              : bg === "orange"
              ? "rgb(230,128,25)"
              : bg === "gray"
              ? "rgb(178,180,188)"
              : bg === "black"
              ? "rgb(32,31,43)"
              : "#fff",
          color: props.dark || !bg ? "rgb(32,31,43)" : "#fff",
          borderRadius: rounded ? "18px" : "3px",
          textAlign: "center",
        }}
      >
        {props.children}
      </div>
    </button>
  );
};
