import React, { useState, useReducer } from "react";
import {
  Modal,
  Box,
  Grid,
  TextField,
  Button,
  Avatar,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoIosSave } from "react-icons/io";

import "./Setting.scss";
import UploadService from "../services/Upload.services";
import SettingService from "../services/Setting.services";
import { toast } from "react-toastify";

const boxStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "40px",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
};

function Settings(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [logo, setLogo] = useState(
    JSON.parse(localStorage.getItem("Settings")).fields.logo[0].url
  );

  const inputReducer = (state, action) => {
    let newState = {};

    if (action.type === "NAME") {
      newState = {
        ...state,
        fields: { ...state.fields, Name: action.payload },
      };
    }

    if (action.type === "PHONE") {
      newState = {
        ...state,
        fields: { ...state.fields, Phone: action.payload },
      };
    }

    if (action.type === "CURRENCY") {
      newState = {
        ...state,
        fields: { ...state.fields, currency: action.payload },
      };
    }

    if (action.type === "MAIL") {
      newState = {
        ...state,
        fields: { ...state.fields, mail: action.payload },
      };
    }

    if (action.type === "LOGO") {
      newState = {
        ...state,
        fields: { ...state.fields, logo: [{ url: action.payload }] },
      };
    }

    return newState;
  };

  const [settings, dispatchSettings] = useReducer(
    inputReducer,
    JSON.parse(localStorage.getItem("Settings"))
  );

  const _settingService = new SettingService();

  function handleImage(file) {
    const _uploadService = new UploadService();
    _uploadService.upload(file, (params) => {
      setLogo(params);
      dispatchSettings({ type: "LOGO", payload: params });
    });
  }

  async function handleSubmit() {
    setIsLoading(true);
    const body = {
      records: [{ id: settings.id, fields: { ...settings.fields } }],
    };
    const res = await _settingService.Edit(body);
    console.log(res);
    localStorage.setItem("Settings", JSON.stringify(res.data.records[0]));
    setIsLoading(false);
  }

  return (
    <Modal open={props.show} onClose={props.onClose}>
      <Box sx={boxStyles}>
        <>
          <div className="setting-container">
            <span className="close-icon" onClick={props.onClose}>
              <AiFillCloseCircle />
            </span>
            <form className="form">
              <Grid>
                {logo && (
                  <IconButton component="label" htmlFor="file-ip-2">
                    <Avatar
                      sx={{ width: 130, height: 130 }}
                      imgProps={{
                        style: {
                          objectFit: "contain",
                        },
                      }}
                      src={logo}
                    />
                    <input
                      hidden
                      type="file"
                      id="file-ip-2"
                      accept="image/*"
                      onChange={(e) => {
                        handleImage(e.target.files[0]);
                      }}
                    />
                  </IconButton>
                )}
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <label className="label">Nom</label>
                  <TextField
                    fullWidth
                    label="Nom"
                    type="text"
                    value={settings.fields.Name}
                    onChange={(e) =>
                      dispatchSettings({
                        type: "NAME",
                        payload: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <label className="label">Email</label>
                  <TextField
                    fullWidth
                    label="Email"
                    type="text"
                    value={settings.fields.mail}
                    onChange={(e) =>
                      dispatchSettings({
                        type: "MAIL",
                        payload: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <label className="label">Numero Telephone</label>
                  <TextField
                    fullWidth
                    label="Num Tel"
                    type="text"
                    value={settings.fields.Phone}
                    onChange={(e) =>
                      dispatchSettings({
                        type: "PHONE",
                        payload: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <label className="label">Devise</label>
                  <TextField
                    fullWidth
                    label="Nom"
                    type="text"
                    value={settings.fields.currency}
                    onChange={(e) =>
                      dispatchSettings({
                        type: "CURRENCY",
                        payload: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
            </form>
          </div>
          <Grid container justifyContent="flex-end" marginTop={5}>
            <LoadingButton
              onClick={handleSubmit}
              loading={isLoading}
              endIcon={<IoIosSave />}
              variant="contained"
              size="large"
            >
              Enregistrer
            </LoadingButton>
          </Grid>
        </>
      </Box>
    </Modal>
  );
}

export default Settings;
