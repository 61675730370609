import React, { useEffect, useState, useReducer } from "react";
import { useHistory, useParams } from "react-router-dom";

import Loading from "../../components/Loading";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import ChauffeurService from "../../services/Chauffeur.services";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { IoIosSave } from "react-icons/io";

import "./UpdateChauffeur.css";

function UpdateChauffeur() {
  const { id: chauffeurId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const history = useHistory();

  const checkValidity = (state) => {
    if (state.fields.Name.length === 0) return false;
    if (state.fields.NumIdentite.length !== 8) return false;
    if (state.fields.NumTel.length < 8) return false;
    return true;
  };

  const _chauffeurService = new ChauffeurService();

  const inputReducer = (state, action) => {
    let newState = {};

    if (action.type === "FETCHED") {
      return action.payload;
    }

    if (action.type === "NAME") {
      newState = {
        ...state,
        fields: { ...state.fields, Name: action.payload },
      };
    }

    if (action.type === "NUMIDENTITE") {
      newState = {
        ...state,
        fields: { ...state.fields, NumIdentite: action.payload },
      };
    }

    if (action.type === "NUMTEL") {
      newState = {
        ...state,
        fields: { ...state.fields, NumTel: action.payload },
      };
    }

    if (action.type === "IDENTITE_PHOTO") {
      newState = {
        ...state,
        fields: { ...state.fields, IdentitePhoto: [{ url: action.payload }] },
      };
    }

    if (action.type === "PERMIS_PHOTO") {
      newState = {
        ...state,
        fields: { ...state.fields, PermisPhoto: [{ url: action.payload }] },
      };
    }

    setIsValid(checkValidity(newState));
    return newState;
  };

  const [chauffeurData, dispatchChauffeurData] = useReducer(inputReducer, null);

  function getBase64(file, type) {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = async () => {
      const res = await _chauffeurService.uploadFileToServeur(
        fileReader.result
      );
      dispatchChauffeurData({ type, payload: res.data.image_url });
    };
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = await _chauffeurService.GetById(chauffeurId);
      dispatchChauffeurData({ type: "FETCHED", payload: res.data });
      console.log(res.data);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const body = JSON.stringify({
      records: [
        {
          id: chauffeurData.id,
          fields: {
            ...chauffeurData.fields,
          },
        },
      ],
    });
    const _chauffeurService = new ChauffeurService();
    await _chauffeurService.Edit(body);
    setIsLoading(false);
    history.push(`/chauffeurs`);
    toast.success("Chauffeur Modifié!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <>
      <Loading isOn={isLoading} />
      {chauffeurData && !isLoading && (
        <form className="form">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <label className="label">Nom</label>
              <TextField
                label="Nom"
                type="text"
                fullWidth
                value={chauffeurData.fields.Name}
                onChange={(e) =>
                  dispatchChauffeurData({
                    type: "NAME",
                    payload: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={4}>
              <label className="label">Numero Carte Identité</label>
              <TextField
                label="Carte Identité"
                fullWidth
                value={chauffeurData.fields.NumIdentite}
                onChange={(e) =>
                  dispatchChauffeurData({
                    type: "NUMIDENTITE",
                    payload: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={4}>
              <label className="label">Numero Telephone</label>
              <TextField
                label="Num Tel"
                fullWidth
                value={chauffeurData.fields.NumTel}
                onChange={(e) =>
                  dispatchChauffeurData({
                    type: "NUMTEL",
                    payload: e.target.value,
                  })
                }
              />
            </Grid>

            <Grid item xs={4}>
              <label className="label">Image Carte Identité</label>
              <div className="center">
                <div className="form-input">
                  {chauffeurData.fields.IdentitePhoto && (
                    <div className="preview">
                      <img
                        alt="carte identité"
                        id="file-ip-1-preview"
                        src={chauffeurData.fields.IdentitePhoto[0].url}
                      />
                    </div>
                  )}
                  <label htmlFor="file-ip-1">Upload Image</label>
                  <input
                    type="file"
                    id="file-ip-1"
                    accept="image/*"
                    onChange={(e) => {
                      getBase64(e.target.files[0], "IDENTITE_PHOTO");
                    }}
                  />
                </div>
              </div>
            </Grid>

            <Grid item xs={4}>
              <label className="label">Image Permis</label>
              <div className="center">
                <div className="form-input">
                  {chauffeurData.fields.PermisPhoto && (
                    <div className="preview">
                      <img
                        alt="permis"
                        id="file-ip-2-preview"
                        src={chauffeurData.fields.PermisPhoto[0].url}
                      />
                    </div>
                  )}
                  <label htmlFor="file-ip-2">Upload Image</label>
                  <input
                    type="file"
                    id="file-ip-2"
                    accept="image/*"
                    onChange={(e) => {
                      getBase64(e.target.files[0], "PERMIS_PHOTO");
                    }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </form>
      )}

      <div className={`${isLoading ? "button-loading" : "send-button"}`}>
        <Button
          size="large"
          variant="contained"
          onClick={handleSubmit}
          endIcon={<IoIosSave />}
          disabled={!isValid}
        >
          Enregistrer
        </Button>
      </div>
    </>
  );
}

export default UpdateChauffeur;
