import React, { Component } from "react";
import {
  Router,
  Switch,
  Route,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import { FaHeart, FaBars } from "react-icons/fa";
import Header from "./header";
import { Filter } from "../components";
import { routes } from "./routing/routes";
import { createBrowserHistory } from "history";
import Aside from "./aside";
import Login from "../screens/Auth/Login";

const Main = ({
  collapsed,
  rtl,
  image,
  handleToggleSidebar,
  handleCollapsedChange,
  handleRtlChange,
  handleImageChange,
  component,
  toggled,
}) => {
  return (
    <Router history={createBrowserHistory()}>
      <Switch>
        {routes.map(({ component: Component, ...rest }) => {
          return (
            <Route
              key={rest.title}
              render={(matchProps) =>
                rest.isPublic && !localStorage.getItem("user") ? (
                  <div className="auth">
                    <Component {...matchProps}></Component>
                  </div>
                ) : !localStorage.getItem("user") ? (
                  <div className="auth">
                    <Login {...matchProps}></Login>
                  </div>
                ) : rest.isPublic ? (
                  <Redirect to="/" />
                ) : (
                  <>
                    <div
                      className={`app ${rtl ? "rtl" : ""} ${
                        toggled ? "toggled" : ""
                      }`}
                    >
                      <Aside
                        collapsed={collapsed}
                        rtl={rtl}
                        toggled={toggled}
                        handleCollapsedChange={handleCollapsedChange}
                        handleToggleSidebar={handleToggleSidebar}
                      />
                      <main>
                        {" "}
                        <Header
                          title={rest.title}
                          handleToggleSidebar={handleToggleSidebar}
                        />
                        <div className="outlet">
                          <Component {...matchProps}></Component>
                        </div>
                      </main>
                    </div>
                  </>
                )
              }
              {...rest}
            />
          );
        })}
      </Switch>
    </Router>
  );
};
export default Main;
