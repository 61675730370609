import React, { Component, useEffect, useState } from "react";
import { FaBars, FaUserAlt } from "react-icons/fa";
import { IoIosArrowDown, IoIosNotificationsOutline } from "react-icons/io";
import { AiOutlineAppstore, AiOutlineUser } from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";

import { useHistory } from "react-router-dom";
import Dropdown from "rc-dropdown";
import "rc-dropdown/assets/index.css";
import { Btn, OutlinedBtn } from "../components/Buttons";
import Switch from "../components/switch";
import Menu, { Item as MenuItem, Divider } from "rc-menu";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons/lib";
import request from "../configs";
import { FcSettings } from "react-icons/fc";
import Settings from "../components/Settings";

export default class Header extends Component {
  render() {
    return (
      <header style={{ display: "flex" }}>
        <span className="title">{this.props.title}</span>
        <HeaderBtn></HeaderBtn>
      </header>
    );
  }
}
const HeaderBtn = () => {
  const [showSettings, setShowSettings] = useState(false);
  const history = useHistory();
  const handleLogout = () => {
    localStorage.clear();
    history.push("/");
  };

  const handleSettings = () => {
    setShowSettings((prevState) => !prevState);
  };

  return (
    <>
      <Settings show={showSettings} onClose={handleSettings} />
      <span>
        <Btn bg="gray" onClick={handleSettings} loading={false}>
          <FcSettings />
        </Btn>
        <Btn bg="red" onClick={handleLogout}>
          <FiLogOut />
        </Btn>
      </span>
    </>
  );
};
