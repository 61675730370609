import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import {
  FaFileContract,
  FaPhotoVideo,
  FaHotel,
  FaPlane,
  FaTachometerAlt,
  FaGem,
  FaList,
  FaGithub,
  FaRegLaughWink,
  FaHeart,
  FaUserFriends,
  FaMosque,
  FaUsers,
  FaUserTie,
} from "react-icons/fa";
import { BiCollapse, BiText } from "react-icons/bi";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { MdDashboard, MdRoute } from "react-icons/md";
import { FaTasks } from "react-icons/fa";
import { BsFillBusFrontFill, BsFillCalendarWeekFill } from "react-icons/bs";
import { HiBellAlert, HiMiniWrenchScrewdriver } from "react-icons/hi2";
import { GiSteeringWheel } from "react-icons/gi";
import { useHistory } from "react-router-dom";
const Aside = ({
  image,
  collapsed,
  rtl,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}) => {
  const history = useHistory();
  const settings = JSON.parse(localStorage.getItem("Settings"));

  return (
    <ProSidebar
      //   image={image ? sidebarBg : false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div className="logo-container">
          {!collapsed && (
            <span
              className="logo"
              style={{
                color: "#fff",
                fontSize: "95%",
                fontWeight: "bolder",
                letterSpacing: ".1rem",
              }}
            >
              {settings.fields.Name}
            </span>
          )}
          <span
            className="btn-collapse "
            onClick={() => handleCollapsedChange(!collapsed)}
          >
            {collapsed ? <AiOutlineDoubleRight /> : <AiOutlineDoubleLeft />}
          </span>
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem
            active={window.location.pathname === "/"}
            icon={<MdDashboard />}
            onClick={() => history.push("/")}
          >
            Dashboard
          </MenuItem>
          <MenuItem
            active={window.location.pathname === "/calendar"}
            icon={<BsFillCalendarWeekFill />}
            onClick={() => history.push("/calendar")}
          >
            Calendrier
          </MenuItem>
          <MenuItem
            active={window.location.pathname.startsWith("/tasks")}
            icon={<FaTasks />}
            onClick={() => history.push("/tasks")}
          >
            Reservations
          </MenuItem>
          <MenuItem
            active={window.location.pathname.startsWith("/vehicule")}
            icon={<BsFillBusFrontFill />}
            onClick={() => history.push("/vehicules")}
          >
            Vehicules
          </MenuItem>
          <MenuItem
            active={window.location.pathname.startsWith("/alerts")}
            icon={<HiBellAlert />}
            onClick={() => history.push("/alerts")}
          >
            Alerts
          </MenuItem>
          <MenuItem
            active={window.location.pathname.startsWith("/clients")}
            icon={<FaUserTie />}
            onClick={() => history.push("/clients")}
          >
            Clients
          </MenuItem>
          <MenuItem
            active={window.location.pathname.startsWith("/circuits")}
            icon={<MdRoute />}
            onClick={() => history.push("/circuits")}
          >
            Missions
          </MenuItem>
          <MenuItem
            active={window.location.pathname.startsWith("/chauffeurs")}
            icon={<GiSteeringWheel />}
            onClick={() => history.push("/chauffeurs")}
          >
            Chauffeurs
          </MenuItem>
          <MenuItem
            active={window.location.pathname.startsWith("/maintenances")}
            icon={<HiMiniWrenchScrewdriver />}
            onClick={() => history.push("/maintenances")}
          >
            Maintenances
          </MenuItem>
        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: "center" }}>
        {/* {JSON.parse(localStorage.getItem("logo")) && (
          <img
            src={
              "https://users.kounouz.travel/" +
              JSON.parse(localStorage.getItem("logo"))
            }
            width="80%"
            className="logomut"
          />
        )} */}
        {!collapsed && (
          <div className="sidebar-footer">
            created by{" "}
            <span>
              <a href="http://www.awslabs.tn/" target="_blank">
                AWSLABS
              </a>
            </span>
          </div>
        )}
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
